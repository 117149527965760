// -----------------------------------------------------------------------------
// Page: Benutzerprofil
// -----------------------------------------------------------------------------

.userprofile-header {
  .intro-head {
    position: relative;
    @include media-breakpoint-down('sm') {
      nav {
        display: inline-block;
      }
      h1 {
        height: 150px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding-left: 120px;
        margin: 10px 0 -20px 0;
        border-top: 1px solid $color-divider;
        clear: both;
      }
    }
  }

  .edit-data {
    float: right;
    white-space: nowrap;

    @include media-breakpoint-up('md') {
      padding-top: 6px;
    }

    @include media-breakpoint-up('lg') {
      padding-top: 11px;
    }

    a {
      padding: 0 8px;

      &:last-child {
        padding-right: 0;
        border-left: 1px solid $color-divider;
      }

      svg {
        margin-right: 5px;
      }

      @include media-breakpoint-up('sm') {
        padding: 0 20px;
        svg {
          margin-right: 10px;
        }
      }
    }
  }

  .user-data {
    position: relative;
    @include media-breakpoint-up('md') {
      padding-left: 160px;
      margin-top: 10px;
    }

    .newsletter-box {
      @include media-breakpoint-up('md') {
        margin-top: -10px;
      }
      @include media-breakpoint-up('lg') {
        margin-top: -30px;
      }
    }
  }

  .user-image {
    position: absolute;
    left: 0;
    top: -145px;
    width: 120px;
    text-align: center;

    figure {
      width: 100px;
      height: 100px;
      margin: 0;
      border-radius: 10px;
      overflow: hidden;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    a {
      //color: $color-text-light;
      display: block;
      white-space: nowrap;
      @include media-breakpoint-up('md') {
        margin-top: 10px;
      }
    }

    @include media-breakpoint-up('md') {
      top: 0;
      width: 140px;
      figure {
        width: 120px;
        height: 120px;
      }
    }
  }

  .user-stats {
    @include media-breakpoint-up('lg') {
      margin: 0 -20px;
      > div {
        padding: 0 20px;

        & + div {
          &::before {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 1px;
            height: calc(100% - 20px);
            background-color: $color-divider;
            content: '';
          }
        }
      }
    }

    @include media-breakpoint-only('lg') {
      div:nth-child(2) {
        &::before {
          height: 100%;
        }
      }
    }

    @media (min-width: 1280px) {
      margin: 0 -40px;
      > div {
        padding: 0 40px;

        & + div {
          &::before {
          }
        }
      }
    }

    dl {
      width: 100%;

      dt, dd {
        font-weight: normal;
        margin: 0 0 20px 0;
      }

      dt {
        clear: left;
        float: left;
      }

      dd {
        clear: right;
        float: right;
        font-family: $font-family-bold;
      }
    }
  }

  .newsletter-box {
    padding: 20px 0 0 0;
    border: 0;
    border-top: 1px solid $color-divider;
    @include media-breakpoint-up('sm') {
      width: 260px;
      padding: 0 0 0 30px;
      border: 0;
    }

    &.is-user {
      @include media-breakpoint-up('sm') {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 0 20px 60px;
        margin: 0;
        position: relative;
        height: 100%;
        &::before {
          display: block;
          position: absolute;
          left: 30px;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: $color-divider;
          content: '';
        }
      }
    }
  }
}

.page-userprofile {
  margin-bottom: 60px;

  @include media-breakpoint-only('md') {
    .pagination {
      a {
        width: $pagination-button-small;
        height: $pagination-button-small;
        line-height: $pagination-button-small;
      }
    }
  }

  .list-header-analysis {
    display: flex;

    h2 {
      flex: 1;
    }

    .arguments {
      flex: 0 0 335px;
      color: $color-text-light-medium;
    }
  }

  .list-header-visits {
    display: flex;

    .perf {
      color: $color-text-light-medium;
      @include media-breakpoint-only('sm') {
        padding-right: 210px;
      }
      @include media-breakpoint-up('xl') {
        padding-right: 210px;
      }
    }
  }

  textarea {
    width: 100%;
    height: 100%;
    background: transparent;
    color: $color-text-dark;
  }
}

// edit user image dialog
#dialog-user-image {
  .upload-control {
    display: inline-block;
    vertical-align: bottom;
    position: relative;
    overflow: hidden;

    .link {
      min-width: auto;
      margin: 0;
      color: $link-color;
    }

    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: -200px;
      width: 600px;
      height: 100%;
      opacity: 0;
      z-index: 1;

      &:hover + .link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .image-rotate-left,
  .image-rotate-right {
    border: 0;
    padding: 0;
    margin: 15px 5px 0 0;
    background: transparent;
    color: $color-text-light-medium;
    outline: none;
  }

  .upload-select-file {
    position: relative;
    overflow: hidden;
    display: inline-block;

    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
  }

  .upload-dropzone {
    border: 1px dashed transparent;
    margin: -20px;
    padding: 20px;
    @include media-breakpoint-up('md') {
      margin: -40px;
      padding: 20px 40px;
    }

    &.dropable {
      background-color: rgba($color-text-light-medium, .1);
      border-color: $color-text-light-medium;
    }
  }

  .image-cropper {
    height: 250px;
  }

  &.no-image {
    .image-editor {
      opacity: .2;
      pointer-events: none;

      .cr-image {
        display: none;
      }
    }

    .btn-outline-primary {
      opacity: .4;
      pointer-events: none;
    }
  }
}

body.is-user {
  .hidden-is-user {
    display: none !important;
  }
}

body:not(.is-user) {
  .hidden-isnot-user {
    display: none !important;
  }
}

.user-image figure,
.author-summary .image {
  background: $color-bg-userimage url("data:image/svg+xml,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%239c9d9e' d='M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z'/%3E%3C/svg%3E") center center no-repeat;
  background-size: 70% 70%;
}
