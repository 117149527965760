// -----------------------------------------------------------------------------
// Analysis list and list element
// -----------------------------------------------------------------------------

// list of analyses
.analysis-list {
  @include media-breakpoint-up('md') {
    &.vertical {
      .block-analysis:first-child {
        border-top: 2px solid $color-bg-dark-medium;
      }

      > :last-child {
        margin-bottom: 0;
      }
    }
    &:not(.vertical) {
      .block-analysis {
        border-top: 2px solid $color-bg-dark-medium;
      }
    }
  }
}

// analysis teaser block
.block-analysis {
  @extend %container-padding-20-40;
  display: flex;
  flex-direction: column;
  width: 100%;

  .responsive-image {
    margin: 0;
  }

  @include media-breakpoint-down('sm') {
    border-bottom: 1px solid $color-border-light;
    .responsive-image {
      display: none;
    }
  }
  @include media-breakpoint-up('md') {
    margin-bottom: 20px;
    border: 1px solid $color-border-light;
  }

  h3 a {
    color: inherit;
  }

  .content {
    > :last-child {
      margin-bottom: 0;
    }
  }

  .read-more {
    display: inline-block;
    margin: 10px 0;
  }

  .slider {
    &:not(.empty) {
      margin-top: auto;
      padding-top: 20px;
      @include media-breakpoint-up('md') {
        padding-top: 40px;
        &::before {
          display: block;
          content: '';
          height: 1px;
          transform: translateY(-20px);
          background-color: $color-border-light;
        }
      }
    }
  }
}

