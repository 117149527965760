// -----------------------------------------------------------------------------
// Fonts
// -----------------------------------------------------------------------------

// https://gwfh.mranftl.com/fonts/figtree?subsets=latin,latin-ext

$font-path: '../../fonts';

@font-face {
  font-family: 'figtree';
  src:  url('#{$font-path}/figtree-v5-latin_latin-ext-regular.woff2') format('woff2'),
        url('#{$font-path}/figtree-v5-latin_latin-ext-regular.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'figtree-bold';
  src:  url('#{$font-path}/figtree-v5-latin_latin-ext-700.woff2') format('woff2'),
        url('#{$font-path}/figtree-v5-latin_latin-ext-700.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'figtree-extrabold';
  src:  url('#{$font-path}/figtree-v5-latin_latin-ext-800.woff2') format('woff2'),
        url('#{$font-path}/figtree-v5-latin_latin-ext-800.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}
