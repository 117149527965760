// -----------------------------------------------------------------------------
// Dialog: Aktienanalyse verfassen
// -----------------------------------------------------------------------------

#dialog-analysis {
  $full-width-max: 900px;
  $small-device: 480px;
  $round-button-size: 42px;
  $round-button-size-small: 30px;

  // dialog container
  .modal-dialog {
    max-height: 100vh;
    @include media-breakpoint-down('xs') {
      height: 100%;
      margin: 0;
    }
    @media (max-width: $full-width-max) {
      position: fixed;
      max-width: 100%;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    @media (min-width: $full-width-max + .2px) {
      position: relative;
      width: 900px;
      max-width: 900px;
    }
  }

  // black header overlay
  .analysis-header-left,
  .analysis-header-right {
    height: 80px;
    @include media-breakpoint-up('sm') {
      height: 110px;
    }
    background-color: $color-bg-dark;
    transition: height .3s;
  }

  .analysis-header-left {
    position: fixed;
    @media (max-width: $full-width-max) {
      width: calc(100% - 20px);
    }
    @media (min-width: $full-width-max + .2px) {
      width: 880px;
    }
    z-index: 101;
  }

  .analysis-header-right {
    position: absolute;
    width: 20px;
    right: 0;
  }

  // after scroll down
  &.small-header {
    .analysis-header-left,
    .analysis-header-right {
      height: 50px;
      @include media-breakpoint-up('sm') {
        height: 70px;
      }
    }
  }

  // save and close buttons
  .button-save,
  .button-close {
    position: absolute;
    top: 25px;
    width: $round-button-size-small;
    height: $round-button-size-small;
    line-height: $round-button-size-small - 3px;
    @include media-breakpoint-up('sm') {
      top: 32px;
      width: $round-button-size;
      height: $round-button-size;
      line-height: $round-button-size - 3px;
    }
    color: $color-text-light;
    z-index: 103;
    transition: top .3s;
  }

  .button-save {
    display: none !important;
    left: 20px;
    @include media-breakpoint-up('md') {
      left: 40px;
    }
    border: 0;
    background-color: transparent;
    padding: 0 5px 0 40px;

    &::before {
      content: '';
      display: block;
      width: 34px;
      height: 44px;
      left: 0;
      top: 1px;
      position: absolute;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cg data-name='Gruppe 439'%3E%3Cg data-name='Gruppe 406'%3E%3Cg data-name='Gruppe 395'%3E%3Cpath data-name='Pfad 304' d='M1 42.872l14.376-7.4 14.376 7.4V7.978A7 7 0 0022.773 1H1z' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3Cg data-name='Gruppe 407'%3E%3Cpath data-name='Linie 45' stroke-width='2' stroke='%2324e800' stroke-linecap='round' stroke-linejoin='round' fill='none' d='M22.365 18.599H9.664'/%3E%3C/g%3E%3Cg data-name='Gruppe 408'%3E%3Cpath data-name='Linie 46' stroke-width='2' stroke='%2324e800' stroke-linecap='round' stroke-linejoin='round' fill='none' d='M16.016 24.95V12.249'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }

    @include media-breakpoint-down('xs') {
      width: 0;
      padding-right: 0;
      overflow: hidden;
      &::before {
        transform: scale(.7);
        top: -7px;
      }
    }
  }

  .button-close {
    right: 20px;
    @include media-breakpoint-up('md') {
      right: 40px;
    }
    text-align: center;
    border: 2px solid $color-bg-dark-medium;
    border-radius: 50%;
    background-color: $color-bg-dark-medium;

    &:hover {
      background-color: $color-bg-dark-lighter;
    }
  }

  // after scroll down
  &.small-header {
    .button-save,
    .button-close {
      top: 10px;
      @include media-breakpoint-up('sm') {
        top: 14px;
      }
    }
  }

  // 3 dot progress navigation
  .progress-nav {
    position: sticky;
    top: 10px;
    height: 34px;
    @include media-breakpoint-up('sm') {
      top: 14px;
      height: 50px;
    }
    text-align: center;
    z-index: 102;

    ul {
      display: inline-block;
      margin: 0 auto;
      padding: 0;
      font-size: .1px;

      li {
        position: relative;
        display: block;
        float: left;
        width: $round-button-size-small;
        height: $round-button-size-small;
        line-height: $round-button-size-small - 2px;
        @include media-breakpoint-up('sm') {
          width: $round-button-size;
          height: $round-button-size;
          line-height: $round-button-size - 2px;
        }
        text-align: center;
        font-family: $font-family-bold;
        font-size: 1rem;
        border: 1px solid $color-bg-progress-nav;
        border-radius: 50%;
        color: $color-text-progress-nav;
        background-color: $color-bg-progress-nav;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);

        & + li {
          margin-left: $round-button-size-small / 3 * 4;
          @include media-breakpoint-up('sm') {
            margin-left: $round-button-size / 3 * 4;
          }

          &::before {
            content: '';
            display: block;
            position: absolute;
            right: $round-button-size-small - 1px;
            width: $round-button-size-small / 3 * 4;
            @include media-breakpoint-up('sm') {
              right: $round-button-size - 1px;
              width: $round-button-size / 3 * 4;
            }
            height: 1px;
            top: 50%;
            background-color: #414141;
          }

          &.active,
          &.done {
            &::before {
              background-color: $color-text-progress-nav-active;
            }
          }
        }

        &.active {
          color: $color-bg-progress-nav-active;
          background-color: $color-text-progress-nav-active;
          border-color: $color-text-progress-nav-active;
        }

        &.done {
          background-color: $color-bg-progress-nav-active;
          color: $color-text-progress-nav-active;
          border-color: $color-text-progress-nav-active;
          cursor: pointer;
        }
      }
    }
  }

  // headline
  .modal-title {
    position: sticky;
    height: 40px;
    z-index: 102;
    margin: 10px 0 15px 0;
    @include media-breakpoint-up('sm') {
      margin: 25px 0 25px 0;
    }

    .topic {
      display: block;
    }
  }

  &.add-argument,
  &.edit-argument,
  &.analysis-step-3 {
    .modal-title {
      margin: 25px 0 0 0;
      @include media-breakpoint-up('sm') {
        margin: 40px 0 10px 0;
      }
    }
  }

  // content
  .modal-body {
    @include scroll-bar($width: 10px);
    padding: 0 calc-rem(20px);
    @include media-breakpoint-up('md') {
      padding: 0 calc-rem(40px);
    }
    overflow-x: hidden;

    // keep content width the same with or without scrollbar
    > * {
      width: calc(100vw - 40px);
      @media (min-width: 768px) and (max-width: $full-width-max) {
        width: calc(100vw - 80px);
      }
      @media (min-width: $full-width-max + .2px) {
        width: 820px;
      }
    }

    // fix firefox overflow padding bug https://bugzilla.mozilla.org/show_bug.cgi?id=748518
    &::after {
      content: '';
      display: block;
      height: 20px;
      @include media-breakpoint-up('md') {
        height: 40px;
      }
    }

    .intro {
      padding: 16px 0 32px 0;
      margin-bottom: 20px;
      text-align: center;
      border-bottom: 1px solid $color-divider;

      &.select-topic {
        padding-bottom: 0;
        border-bottom: none;

        .hidden-text-input {
          border: 0;
          height: 0;
          margin: 0;
          padding: 0;
          overflow: hidden;
        }
      }
    }

    // form elements
    input[type=text],
    input[type=search] {
      width: 100%;
      padding-bottom: 6px;
      color: #ffffff;
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid $color-divider;

      &:focus {
        outline: none;
        border-color: $color-text-light;
        transition: border-color .3s;
      }

      &.xl {
        padding-bottom: 0;
        margin-bottom: 30px;
        line-height: 50px;
        font-family: $font-family-bold;
        font-size: calc-rem(19px);
        @include media-breakpoint-up('sm') {
          line-height: 66px;
          font-family: $font-family-bold;
          font-size: calc-rem(30px);
        }
      }
    }

    :not(.radio-button) > label {
      margin-bottom: calc-rem(20px);
      text-transform: uppercase;
      font-family: $font-family-extrabold;
    }

    textarea {
      display: block;
      width: 100%;
      height: 270px;
      padding: 15px;
      margin-bottom: 30px;
      border: 0;
      color: $color-text-light;
      background-color: $color-bg-editor;
      transition: box-shadow .3s;

      &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.5);
      }
    }
  }

  // step 2 collapsible arguments
  .argument {
    position: relative;
    margin-bottom: 30px;

    .collapse-inner {
      padding: 3px 20px 20px 20px;
      background-color: $color-bg-lightest;
      color: $color-text-dark;

      input,
      :not(.radio-button) > label {
        display: block;
        color: $color-text-dark;
        border-color: $color-input-underline;

        &:focus {
          border-color: $color-input-underline-focus;
        }
      }
    }
  }

  // container for trend info
  .trend {
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid $color-border-light;
    @include media-breakpoint-down('xs') {
      margin-left: -20px;
      margin-right: -20px;
      border-left: 0;
      border-right: 0;
      & + .trend {
        margin-top: -20px;
        border-top: 0;
      }
    }

    .col-input,
    .chart-container {
      display: none;
    }

    .col-quote {
      position: relative;
    }

    &.active {
      border-color: $color-bg-dark;

      .col-input,
      .chart-container {
        display: initial;
      }

      @include media-breakpoint-up('md') {
        .col-quote {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }

    .radio-button {
      label + input + label {
        margin-left: 5px;
      }

      @include media-breakpoint-up('md') {
        label + input + label {
          margin-left: 10px;
        }
      }
    }
  }

  // Charts container
  .chart-container {
    position: relative;
    margin-top: 20px;
    width: inherit;
    height: inherit;

    @include media-breakpoint-down('sm') {
      min-height: 270px;
      margin-bottom: 30px;
    }

    .chart {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 5px;

      .graph {
        border: 0;
      }
    }
  }

  // positiv / negative vote buttons in analysis
  .analysis-vote {
    margin-top: -18px;
    margin-bottom: 30px;
  }

  // add an argument button
  .add-argument {
    @include media-breakpoint-up('sm') {
      padding-top: 10px;
    }
    text-align: center;
    color: $color-text-light-medium;

    button {
      margin: -5px 0 20px 0;
      width: calc-rem(50px);
      height: calc-rem(50px);
      line-height: calc-rem(50px);
      font-size: calc-rem(20px);
      @include media-breakpoint-up('sm') {
        margin-top: 0;
        width: calc-rem(70px);
        height: calc-rem(70px);
        line-height: calc-rem(70px);
        font-size: calc-rem(30px);
      }
      border: 0;
      border-radius: 50%;
      color: $color-text-light;
      background-color: #434343;
    }
  }

  // social share
  .social-share {
    display: inline-flex;
    margin-top: 10px;
  }

  // visibility of elements
  .analysis-only {
    display: block;
  }

  .argument-only {
    display: none;
  }

  &.analysis-step-1 {
    .visible-step-2,
    .visible-step-3 {
      display: none;
    }

    .visible-step-1 {
      display: block;
    }
  }

  &.analysis-step-2 {
    .visible-step-1,
    .visible-step-3 {
      display: none;
    }

    .visible-step-2 {
      display: block;
    }
  }

  &.analysis-step-3 {
    .visible-step-1,
    .visible-step-2 {
      display: none;
    }

    .visible-step-3 {
      display: block;
    }

    // step 3 specific styles
    .progress-nav li {
      margin: 0;

      &::before {
        content: none;
      }
    }

    .modal-body {
      text-align: center;

      img {
        margin: 60px 0;
      }

      hr {
        margin: 30px 0;
        border-color: $color-divider;
      }
    }
  }

  //// scrollbars
  //.modal-body::-webkit-scrollbar {
  //  width: 16px;
  //}
  //
  //.modal-body {
  //  scrollbar-width: 9px;
  //  scrollbar-color: $color-bg-dark-lighter $color-bg-dark;
  //}
  //
  //.modal-body::-webkit-scrollbar-track {
  //  background: $color-bg-dark;
  //}
  //
  //.modal-body::-webkit-scrollbar-thumb {
  //  background-color: $color-bg-dark-lighter;
  //  border-radius: 2px;
  //  border: 4px solid $color-bg-dark;
  //}

  // templates for dynamic elements
  .templates {
    display: none;
  }

  // positioning action menu
  .dropdown.actions {
    position: absolute;
    top: 11px;
  }

  .argument > .dropdown.actions {
    right: 50px;
  }

  .trend > .dropdown.actions {
    right: 10px;
  }

  // classes for action menu
  .argument,
  .trend {
    opacity: 1;
    transition: opacity .3s;

    &.to-delete {
      opacity: 0;
    }

    &.first {
      > .dropdown.actions {
        .action-up {
          opacity: .4;
          pointer-events: none;
        }
      }
    }

    &.last {
      > .dropdown.actions {
        .action-down {
          opacity: .4;
          pointer-events: none;
        }
      }
    }
  }

  .argument {
    &.first.last {
      > .dropdown.actions {
        display: none;
      }
    }
  }

  #dialog-analysis-preview,
  #dialog-analysis-publish {
    display: block;
    width: 100%;
    @include media-breakpoint-up('sm') {
      width: auto;
    }
  }

  #dialog-analysis-preview {
    margin-bottom: 20px;
    @include media-breakpoint-up('sm') {
      margin: 0;
    }
  }

  .analysis-preview {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @media (min-width: $full-width-max) {
      top: 20px;
      right: 20px;
      bottom: 20px;
      left: 20px;
    }
    background-color: $color-bg-dark;
    color: #ffffff;
    z-index: 103;

    .modal-title {
      margin-top: 13px;
    }

    iframe {
      border: 0;
      border-top: 1px solid $color-divider;
      position: absolute;
      top: 50px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 50px);
    }

    @include media-breakpoint-up('sm') {
      .modal-title {
        margin-top: 20px;
      }
      iframe {
        top: 70px;
        height: calc(100% - 70px);
      }
    }
  }

  &.add-argument,
  &.edit-argument {
    .analysis-only {
      display: none !important;
    }

    .argument-only {
      display: block;
    }

    .button-save,
    .progress-nav li:nth-child(2),
    #dialog-analysis-preview {
      display: none;
    }

    #dialog-analysis-publish {
      margin-left: auto;
    }
  }

  // different dialog mode element visibilities
  &.add-analysis {
    .hidden-mode-add-analysis {
      display: none !important;
    }
  }

  &.edit-analysis {
    .hidden-mode-edit-analysis {
      display: none !important;
    }
  }

  &.add-argument {
    .hidden-mode-add-argument {
      display: none !important;
    }
  }

  &.edit-argument {
    .hidden-mode-edit-argument {
      display: none !important;
    }
  }
}


