// -----------------------------------------------------------------------------
// Mixins
// -----------------------------------------------------------------------------

// px to rem with $font-size-base as 1rem base
@function calc-rem($size, $base: $font-size-base) {
  $rem-size: $size / $base;
  @return $rem-size * 1rem;
}

@mixin scroll-bar($track-color: $color-bg-dark, $thumb-color: $color-bg-dark-lighter, $width: 6px) {
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: $thumb-color $track-color;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border-radius: 3px;
    border: 1px solid $track-color;
  }
}
