// -----------------------------------------------------------------------------
// Responsive data lists for user profile
// -----------------------------------------------------------------------------

// common
.data-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid $color-border-light;
  border-bottom: 1px solid $color-border-light;

  li {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $color-border-light;
    padding: 10px 0;

    &.marker-neg,
    &.marker-pos {
      &::before {
        top: 17px;
      }
    }

    &.hidden {
      .link {
        opacity: .30;
        filter: blur(1px);
      }
    }

    > a {
      color: $color-text-dark;
      font-family: $font-family-bold;
    }

    .link,
    .date,
    .perf {
      vertical-align: top;
    }

    .perf {
      flex: 0 0 120px;
      text-align: right;
      padding-right: 40px;
    }

    .link {
      flex-grow: 1;
      padding-right: 20px;
      @media (max-width: map-get($grid-breakpoints, 'sm')), (min-width: map-get($grid-breakpoints, 'md')) and (max-width: map-get($grid-breakpoints, 'xl')) {
        flex: 0 0 100%;
      }
    }

    .date {
      flex: 0 0 130px;
    }

    .vote {
      flex: 0 0 80px;
      text-align: right;
      margin-left: auto;
    }

    .date,
    .vote {
      white-space: nowrap;
      color: $color-text-light-medium;
    }

    .delete {
      position: relative;
      flex: 0 0 40px;
      text-align: right;
      color: $color-text-light-medium;

      &:hover {
        color: $color-text-dark;
      }
    }
  }

  // visited stocks and trends
  &.list-visited {
    .link {
      padding-right: 0;
      flex: 0 0 calc(100% - 120px - 130px - 40px);
    }

    @media (max-width: map-get($grid-breakpoints, 'sm')), (min-width: map-get($grid-breakpoints, 'md')) and (max-width: map-get($grid-breakpoints, 'xl')) {
      .link {
        flex: 0 0 calc(100% - 120px);
      }
      .perf {
        padding-right: 0;
      }
      .date,
      .delete {
        flex: 0 0 50%;
      }
    }
  }

  // analyses
  &.list-analysis {
    .vote {
      flex: 0 0 50%;
      text-align: right;
    }

    .count {
      flex: 0 0 100%;
      color: $color-text-light-medium;
    }

    .argument {
      flex: 0 0 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include media-breakpoint-up('sm') {
      .link {
        flex: 0 0 calc(100% - 150px - 40px);
      }
      .date {
        flex: 0 0 150px;
      }
      .vote {
        flex: 0 0 40px;
      }
      .count {
        flex: 0 0 120px;
      }
      .argument {
        flex: 0 0 calc(100% - 120px);
      }
    }

    @include media-breakpoint-up('xl') {
      .link {
        flex: 0 0 calc(100% - 5px - 170px - 80px - 130px - 330px);;
      }
      .date {
        flex: 0 0 170px;
      }
      .vote {
        flex: 0 0 80px;
        text-align: left;
      }
      .count {
        flex: 0 0 130px;
      }
      .argument {
        flex: 0 0 330px;
      }
    }
  }

  // arguments and comments
  &.list-arguments,
  &.list-comments {
    @include media-breakpoint-up('sm') {
      .link {
        flex: 1 1;
      }
      .date {
        flex: 0 0 150px;
      }
      .vote {
        flex: 0 0 40px;
        text-align: right;
      }
    }
    @include media-breakpoint-up('xl') {
      .date {
        flex: 0 0 285px;
      }
      .vote {
        flex: 0 0 180px;
        text-align: left;
        padding-left: 20px;
      }
    }
  }
}

