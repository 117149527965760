// -----------------------------------------------------------------------------
// Embedded analysis info block in header an teasers
// -----------------------------------------------------------------------------

.analysis-intro {
  @extend %container-padding-20-40;
  color: $color-text-light;

  .divider {
    height: 1px;
    margin: 20px 0;
    background-color: $color-divider;
  }
}
