// -----------------------------------------------------------------------------
// Stock and fond/ETF blocks
// -----------------------------------------------------------------------------

.stock {

  @include media-breakpoint-down('sm') {
    border-top: 1px solid $color-border-light;
  }
  @include media-breakpoint-up('md') {
    border: 1px solid $color-border-light;
  }

  article {
    padding: 20px 30px;
    @include media-breakpoint-up('lg') {
      padding: 20px 50px;
    }

    h3 {
      a {
        color: $color-text-dark;
      }
    }

    .info {
      display: flex;
      flex-flow: column;

      @include media-breakpoint-up('sm') {
        padding-right: 20px;
      }

      @include media-breakpoint-up('lg') {
        padding-right: 40px;
      }
    }
  }

  .chart {
    margin-top: 20px;
    height: 160px;
    @include media-breakpoint-up('sm') {
      margin: 5px 0;
      height: 200px;
    }
  }

  // ETF logo
  .logo {
    max-width: 100px;
    max-height: 50px;
    @include media-breakpoint-up('sm') {
      margin-bottom: 15px;
    }
  }

  // other arguments list right of stock block
  .other-arguments {
    padding: 0 20px 20px 20px;
    @include media-breakpoint-up('md') {
      padding: 20px 20px 20px 0;
    }
    @include media-breakpoint-up('lg') {
      padding-right: 40px;
    }

    a {
      display: block;
      position: relative;
      padding: 10px 20px 10px 25px;
      color: $color-text-dark;
      line-height: 20px;

      &::before {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      svg {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      .date {
        display: block;
        color: $color-text-light-medium;
      }

      &:hover {
        text-decoration: none;

        .title {
          text-decoration: underline;
        }
      }
    }

    a + a {
      border-top: 1px solid $color-border-light;
    }
  }
}

// list of stocks or etfs
.stock-list {

  > :first-child {
    border-top: 2px solid $color-bg-dark-medium;
  }

  .use-bg,
  .dygraph-axis-label-y,
  .rgraph_accessible_text_xaxis_labels,
  .rgraph_accessible_text_yaxis_labels {
    background-color: #ffffff;
  }

  .graph {
    border-color: $color-border-light;
  }

  div.stock:nth-of-type(even) { // dunkel
    background-color: $color-bg-dark-medium;
    color: $color-text-light;
    border-color: $color-bg-dark-medium;

    h3 {
      a {
        color: $color-text-light;
      }
    }

    .perc {
      background-color: $color-bg-dark-medium;
    }

    .collapse-control {
      background-color: $color-bg-dark-medium;
      color: $color-text-light;
    }

    .use-bg,
    .dygraph-axis-label-y,
    .rgraph_accessible_text_xaxis_labels,
    .rgraph_accessible_text_yaxis_labels {
      background-color: $color-bg-dark-medium;
    }

    .graph {
      border-color: $color-divider;
    }

    .other-arguments {
      a {
        color: $color-text-light;
        & + a {
          border-top-color: $color-divider;
        }
      }
    }
  }

  @include media-breakpoint-down('sm') {
    .collapse-control {
      &::before {
        content: '';
        display: block;
        position: relative;
        top: -1rem;
        height: 1px;
        background-color: $color-border-light;
      }
    }
  }

  @include media-breakpoint-up('md') {
    .stock + .stock {
      margin-top: 20px;
    }
  }

}


