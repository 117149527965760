// -----------------------------------------------------------------------------
// Vote buttons
// -----------------------------------------------------------------------------

// common
.vote {
  display: inline-block;
  white-space: nowrap;

  svg {
    transition: transform .2s;
  }

  .done {
    display: none;
  }

  > a {
    text-decoration: none;
    color: $color-vote-dark;

    &:hover {
      text-decoration: none;
      //color: currentcolor;

      svg {
        transform: scale(1.4);
      }
    }
  }

  > span {
    color: $color-text-light-medium;
  }

  .votes {
    position: relative;
    display: inline-block;
    min-width: 21px;
    padding-left: 5px;
    cursor: pointer;

    &:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }

  &.voted {
    .fa-thumbs-up {
      display: none;
    }

    .done {
      display: inline;
    }
  }

  .dropdown-menu {
    margin-top: -2px;

    .show-more {
      padding: 5px 20px;
      color: $color-text-light-medium;
      display: none;
    }
  }
}

// on dark backgrounds
.bg-dark,
.bg-gray,
.bg-black,
.bg-blue,
.analysis-arguments .argument.open,
.stock-list div.stock:nth-of-type(even) {
  .vote > a {
    color: $color-vote-light;
  }
}
