// -----------------------------------------------------------------------------
// Page: Trendportrait
// -----------------------------------------------------------------------------

.trend-header {
  .intro {
    @include media-breakpoint-up('md') {
      padding-right: 20px;
    }
    @include media-breakpoint-down('sm') {
      padding-bottom: 20px;
    }

    .name {
      padding: 0 0 20px 0;
    }

    .responsive-image {
      margin: 0;
    }
  }

  .info {
    @include media-breakpoint-up('md') {
      padding-left: 20px;
    }

    .chart-container {
      height: 100%;

      .chart {
        height: 50vw;
        @include media-breakpoint-up('md') {
          height: 100%;
        }
      }
    }
  }
}

.page-trend {
  .analysis-intro {
    margin-bottom: 20px;
  }

  .additional-trends {
    @extend %container-padding-20-40;
    margin-bottom: 20px;
    @include media-breakpoint-up('md') {
      margin-bottom: 40px;
    }

    h2 {
      margin: 20px 0;
    }

    .slider.trends .slide {
      width: 190px;

      figure,
      .chart {
        height: 80px;
      }
    }
  }
}
