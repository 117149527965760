// -----------------------------------------------------------------------------
// Forms and form elements
// -----------------------------------------------------------------------------

input[type=password],
input[type=email],
input[type=text],
input[type=search] {
  padding: 0;
  width: 100%;
  line-height: 40px;
  color: $color-text-dark;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid $color-input-underline;

  &:focus {
    outline: none;
    border-color: $color-input-underline-focus;
    transition: border-color .3s;
    background-color: transparent;
  }
}

// custom checkbox as slide button
.custom-checkbox {
  position: relative;

  input[type='checkbox'] {
    $width: 70px;
    $height: 28px;
    position: absolute;
    width: $width;
    height: $height;
    margin: 0 !important;
    opacity: 0;
    z-index: 2;
    cursor: pointer;

    & + label {

      position: relative;
      margin: 0;
      min-height: $height;

      &:not(:empty) {
        padding-top: 3px;
        display: inline-block;
        padding-left: $width + 10px;
      }

      &:empty {
        display: inline-block;
        padding-left: $width;
      }

      &::before,
      &::after {
        display: block;
        position: absolute;
        background-color: transparent;
        content: '';
      }

      &::before {
        top: 0;
        left: 0;
        width: $width;
        height: $height;
        border: 1px solid $color-input-underline;
        transition: all .1s;
      }

      &::after {
        width: ($width / 2) - 4px;
        height: $height - 8px;
        top: 4px;
        left: ($width / 2);
        transition: all .1s;
        background: $color-input-underline center no-repeat;
        background-size: 12px 12px;
        background-image: url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-times fa-w-10' aria-hidden='true' data-prefix='far' data-icon='times' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23ffffff' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
      }
    }

    &:checked + label {
      &::before {
        border-color: $color-pos;
      }

      &:after {
        left: 4px;
        background-color: $color-pos;
        background-image: url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-check fa-w-16' aria-hidden='true' data-prefix='far' data-icon='check' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E");
      }
    }

    &:hover + label {
      &::before {
        border-color: $color-pos;
      }
    }
  }
}

form {
  .form-group {
    position: relative;

    label {
      margin-bottom: 0;
      font-size: calc-rem(15px);
    }
  }

  .form-control {
    display: inline-block;
    width: 100%;
    box-shadow: none;
    height: 42px;
  }

  input[type=password] {
    padding-right: 30px;
  }

  // validation popovers
  .popover {
    width: auto;
    max-width: 300px;

    .popover-body {
      padding-left: 30px;

      svg {
        position: absolute;
        top: 9px;
        left: 7px;
        width: 18px;
        height: 18px;
        color: $color-invalid-border;
      }
    }
  }

  // form fields with validation errors
  .invalid {
    outline: 3px solid $color-invalid-border;
  }

  // password show/hide toggle
  .toggle-pw {
    position: absolute;
    margin-top: 13px;
    right: 0;
    border: 0;
    padding: 0;
    background: transparent;
    color: $color-text-light-medium;

    &:focus {
      outline: 0;
    }

    input:focus + & {
      $color: $color-text-light;
    }

    .fa-eye {
      display: none;
    }

    .fa-eye-slash {
      display: block;
    }

    &.pw-shown {
      .fa-eye {
        display: block;
      }

      .fa-eye-slash {
        display: none;
      }
    }
  }

  .form-group.col-12 .toggle-pw {
    right: 10px;
  }
}

// forms on dark backgrounds
.page-header,
form.modal-content,
.bg-dark {
  .form-group {

    label {
      color: $color-text-light-medium;
    }

    input {
      color: $color-text-light;
      border-color: $color-divider;

      &:focus {
        border-color: $color-border-light;
      }
    }
  }

  .custom-checkbox {
    input[type='checkbox']:not(:checked) {
      &:not(:hover) + label {
        &::before {
          border-color: $color-divider;
        }
      }

      & + label {
        &::after {
          background-color: $color-divider;
        }
      }
    }
  }
}

