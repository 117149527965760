// -----------------------------------------------------------------------------
// Content box with border and top line
// -----------------------------------------------------------------------------

.content-box {
  .label {
    margin: 10px 0 20px 0;
  }

  @extend %container-padding-20-40;
  border: 1px solid $color-border-light;
  @include media-breakpoint-down('sm') {
    border-left: 0;
    border-right: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  &.top-border {
    border-top: 2px solid $color-bg-dark-medium;
  }

  .load-more {
    padding: 20px 0 0 0;
    border-top: 0;
    @include media-breakpoint-up('md') {
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }

  &.bg-dark {
    border-color: $color-bg-dark;
    color: $color-text-light;
  }
}

.row .content-box:only-child {
  height: 100%;
}
