// -----------------------------------------------------------------------------
// Page: Aktiennalyse
// -----------------------------------------------------------------------------

.analysis-header {
  position: relative;

  .intro {
    @include media-breakpoint-up('md') {
      padding-right: 20px;
    }
    .content {
      overflow-wrap: break-word;
      margin-bottom: 1rem;
      > :last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-up('lg') {
        @include scroll-bar;
        max-height: 485px;
        overflow: auto;
      }
    }
  }

  .info {
    @extend %divider-before;
    @include media-breakpoint-up('md') {
      padding-left: 20px;
      &::before {
        content: none;
      }
    }

    .chart-container {
      height: 250px;
      @include media-breakpoint-up('md') {
        height: 400px;
      }
      margin: 0 0 12px 0;
    }

    h2.label {
      margin: 24px 0 10px 0;
    }
  }

  .dropdown-zone {
    position: absolute;
    right: 20px;
    bottom: 15px;
    @include media-breakpoint-up('lg') {
      right: 40px;
      bottom: 35px;
    }
    .btn-dark {
      border-color: $color-text-light-medium;
    }
  }

  .display-hidden {
    display: none;
  }

  &.hidden {
    background-color: #444444;
    > :not(.dropdown-zone) {
      opacity: .35;
      filter: blur(2px) grayscale(100%);
    }

    .display-default {
      display: none;
    }

    .display-hidden {
      display: block;
    }
  }
}

.analysis-arguments {
  .argument {
    position: relative;
    @include media-breakpoint-up('md') {
      & + .argument {
        margin-top: 20px;
      }
      &.to-delete + .argument {
        margin-top: 0;
      }
    }
    transition: background-color .3s;

    .expander {
      padding: 20px 0;
      @include media-breakpoint-down('sm') {
        border-top: 1px solid $color-border-light;
      }
      @include media-breakpoint-up('md') {
        border: 1px solid $color-border-light;
      }

      .meta {
        padding: 0 20px;
        @include media-breakpoint-up('lg') {
          padding: 0 40px;
        }
        margin: 0;
      }
    }

    .argument-collapse-control {
      display: block;
      border: 0;
      @extend %container-padding-20-40;
      padding-top: 10px;
      padding-bottom: 10px;
      text-transform: none;
      white-space: normal;
      text-align: left;
      font-family: $font-family-base;
      background-color: transparent;
      @include media-breakpoint-up('md') {
        &:hover {
          background-color: #ececec;
        }
      }

      > svg {
        right: 20px;
        @include media-breakpoint-up('lg') {
          right: 40px;
        }
        font-size: 24px;
        transition: all .2s;
        transform: translateY(-50%) rotateX(180deg);
      }

      &.collapsed {
        > svg {
          transform: translateY(-50%) rotate(0);
        }
      }

      h3 {
        max-width: 80%;
        margin: 0;
      }
    }

    .version-selector,
    .display-hidden {
      display: none;
    }

    .dropdown.actions {
      position: absolute;
      top: 13px;
      right: 12px;
      @include media-breakpoint-up('lg') {
        right: 32px;
      }
      z-index: 1;

      .btn-actions {
        background: transparent;
        border-color: transparent;
        color: $color-text-light-medium;

        &:hover {
          background-color: $color-bg-light;
        }
      }

      .dropdown-menu {
        top: 0 !important;
        right: 100% !important;
        left: auto !important;
        margin-top: 0;
        margin-right: 0.125rem;
        transform: none !important;
      }
    }

    &.open {
      background-color: $color-bg-dark-medium;
      color: $color-text-light;

      .argument-collapse-control {
        border-color: $color-bg-dark-medium;
        color: $color-text-light;

        &:hover {
          background-color: $color-bg-dark;
        }
      }

      .version-selector {
        display: inline;
      }

      .dropdown.actions .btn-actions {
        &:hover {
          background-color: $color-bg-dark;
        }
      }

      .use-bg,
      .dygraph-axis-label-y {
        background-color: $color-bg-dark-medium;
      }
    }

    &.hidden {
      outline: 1px dashed $color-border-light;

      > :not(.dropdown-zone) {
        opacity: .25;
        filter: blur(2px);
      }

      .display-default {
        display: none;
      }

      .display-hidden {
        display: block;
      }
    }

    .argument-content {
      @extend %divider-before;
      @extend %container-padding-40-40;
      padding-top: 0;

      &::before {
        margin: 0 0 20px 0;
      }

      .content {
        @extend %divider-after;
      }

      h4 {
        font-weight: normal;
        font-family: $font-family-bold;
        margin: 10px 0 5px 0;
        a {
          color: inherit;
          .identifier {
            display: inline-block;
            margin-left: 10px;
            font-family: $font-family-sans-serif;
            font-size: 14px;
          }
        }
      }

      .intro {
        margin-bottom: 20px;
      }

      .share {
        text-align: center;
      }

      .social-share {
        display: inline-flex;
      }
    }

    .chart {
      width: 50%;
      height: 120px;
      margin: 5px 0 10px 20px;
      float: right;

      @include media-breakpoint-down('md') {
        .chart-perf {
          font-size: 14px;

          .p {
            width: 45px;
          }
        }

        .dygraph-axis-label {
          font-size: 11px;
        }
      }

      @include media-breakpoint-up('sm') {
        width: 40%;
        height: 150px;
      }
      @include media-breakpoint-up('md') {
        width: 50%;
      }
      @include media-breakpoint-up('lg') {
        width: 40%;
      }
    }
  }
}








