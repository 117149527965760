body.typeahead-open {
  @include media-breakpoint-up('md') {

    .typeahead__result {
      opacity: 1;
      transition: opacity .2s;
    }

    &::before {
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background: #000000;
      opacity: .7;
      transition: opacity .2s;
      z-index: 999;
    }

    &.typeahead-fade {
      .typeahead__result {
        opacity: 0;
      }

      &::before {
        opacity: 0;
      }
    }
  }

  .typeahead__item.active > a {
    background: darken($typeahead-item-hover-background, 60%) !important;
  }
}
