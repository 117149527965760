// -----------------------------------------------------------------------------
// Page headers
// -----------------------------------------------------------------------------

.page-header {
  @extend %container-padding-40-40;

  color: $color-text-light;

  &.dark {
    background-color: $color-bg-dark;
  }

  &.dark-medium {
    background-color: $color-bg-dark-medium;
  }

  .intro-head {
    @extend %divider-after;
    @include media-breakpoint-down('sm') {
      margin-bottom: 0;
    }
    line-height: 32px;
  }

  .name {
    .vote {
      margin-top: 3px;
      @include media-breakpoint-up('md') {
        margin-top: 8px;
      }
    }

    .perf {
      line-height: 40px;
      font-family: $font-family-bold;
    }

    .date {
      font-size: calc-rem(14px);
      color: $color-text-light-medium;
    }
  }
}
