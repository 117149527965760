// -----------------------------------------------------------------------------
// Data tables with mobile swipe wrapper
// -----------------------------------------------------------------------------

.table-wrap {
  @include scroll-bar;
  position: relative;
  padding: 0 0 0 10px; // leave some space for blue arrow
  margin: 0 0 0 -10px;
  overflow-x: auto;
}

// sortable tables
th.sortable,
th .sortable {
  background: none !important;

  &::after {
    top: 24px;
  }
}

// common data table styles
.data-table {
  width: 100%;
  border-top: 1px solid $color-divider;

  @include media-breakpoint-down('sm') {
    font-size: calc-rem(14px);
  }

  th,
  td {
    padding: 8px;
    text-align: right;
    vertical-align: top;

    &:first-child {
      text-align: left;
      padding-left: 0;
    }

    a {
      color: inherit;
    }
  }

  tr.divider td {
    border-top: 1px solid $color-divider;
  }

  th {
    font-family: $font-family-extrabold;
    font-weight: normal;
    line-height: 32px;
    text-transform: uppercase;
    border-bottom: 1px solid $color-divider;
    white-space: nowrap;

    &.sortable {
      padding-right: 20px !important;

      &::after {
        top: 17px;
        background-position: 0 14px;
      }

      &.desc {
        &::after {
          background-position: 9px 14px;
        }
      }

      &.asc {
        &::after {
          background-position: 18px 14px;
        }
      }
    }
  }

  td {
    border-top: 1px solid $color-divider-dark;
  }

  // table with clickable rows
  &.active-rows1 {
    tbody tr:not(.active) {
      cursor: pointer;

      &:hover td:first-child {
        text-decoration: underline;
      }
    }

    td {
      padding: 0;

      a {
        display: block;
        padding: 8px;

        &:hover {
          text-decoration: none;
        }
      }

      &:first-child {
        a {
          padding-left: 0;
        }

        &::before {
          float: left;
          transform: translateY(16px);
        }
      }
    }

    // active entry highlight
    tr.active {
      background-color: $color-bg-dark-lighter;

      td {
        box-shadow: -10px 0 0 0 $color-bg-dark-lighter, 0 0 0 0 $color-bg-dark-lighter;
        border-color: transparent;
      }

      td:first-child {
        font-family: $font-family-bold;
        color: $color-text-light;

        &::before {
          content: '';
          display: inline-block;
          border: 4px solid transparent;
          border-left: 5px solid $color-text-light;
          margin: -4px 0 1px -9px;
          @include media-breakpoint-up('md') {
            margin-top: 0;
          }
        }
      }

      & + tr {
        td {
          border-top-color: transparent;
        }
      }
    }
  }

  // table with scrollable tbody
  &.scroll-table {
    $scrollbar-offset: 11px;
    $thead-height: 50px;

    height: 100%;

    tbody {
      @include scroll-bar;
      display: block;
      position: absolute;
      width: 100%;
      top: $thead-height;
      bottom: 0;
      left: 0;
      padding-left: 10px;
      overflow-y: auto;
    }

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    thead {
      th:last-child {
        padding-right: $scrollbar-offset;
      }
    }

    td {
      border-top: 0;
      border-bottom: 1px solid $color-divider-dark;
    }
  }

  // table with animated rows
  &.animated-rows,
  &.active-rows {
    $active-padding: 5px;

    tbody tr {
      position: relative;

      td {
        a {
          display: block;
        }

        &.stock-link {
          padding-left: 0;
          padding-right: 0;
          width: 20px;
        }
      }

      td:first-child {
        position: relative;
        padding-left: $active-padding;
      }

      &.active {
        td {
          background-color: #333;
        }
        td:first-child a {
          font-family: $font-family-bold;

          &:hover {
            text-decoration: none;
          }
        }
        &.nice5 td {
          background-color: #444;
        }
      }
    }

    &.on-gray {
      &.active {

      }
    }

    // TODO on-black as well?
  }

  // 'finder' specific (arrows)
  .table-wrap-trends &.animated-rows,
  .table-wrap-trends &.active-rows {
    $active-padding: 5px;
    $fade-transition: 5s;

    tbody tr {
      background-position: 0 0;
      transform: translateX(-$active-padding);
      background-color: unset;

      @include media-breakpoint-down('sm') {
        background-size: 100% 200%;
        background-image: linear-gradient(180deg, transparent 0, transparent 50%, $color-bg-dark-lighter 50%, $color-bg-dark-lighter 100%);
        width: calc(100% + #{$active-padding});

        td:last-child {
          // Pfeil
          &::before,
          &::after {
            position: absolute;
            content: '';
            display: block;
            width: 30px;
            height: 12px;
            bottom: -11px;
            background-repeat: no-repeat;
            background-position: 0 -12px;
          }

          &::before {
            right: 50%;
            background-image: linear-gradient(to left bottom, $color-bg-dark-lighter 50%, transparent 50%);;
          }

          &::after {
            right: calc(50% - 30px);
            background-image: linear-gradient(to right bottom, $color-bg-dark-lighter 50%, transparent 50%);;
          }
        }

        &.active {
          transition: background-position .2s linear;
          background-position: 0 -100%;

          td:last-child {
            &::before,
            &::after {
              transition: background-position .3s cubic-bezier(1, 0, 1, 1);
              background-position: 0 0;
            }
          }

          &.fade-out {
            transition: background-position $fade-transition linear;
            background-position: 0 -200%;

            td:last-child {
              &::before,
              &::after {
                transition: background-position $fade-transition ease-in;
                background-position: 0 -12px;
              }
            }
          }

        }
      }

      @include media-breakpoint-up('md') {
        background-size: 200% 100%;
        background-image: linear-gradient(90deg, transparent 0, transparent 50%, $color-bg-dark-lighter 50%, $color-bg-dark-lighter 100%);
        width: calc(100% + #{$active-padding - 2px});

        td:last-child {
          padding-right: 12px;

          // Pfeil
          &::before,
          &::after {
            position: absolute;
            content: '';
            display: block;
            width: 12px;
            height: 50%;
            right: 0;
          }

          &::before {
            top: 0;
            background: linear-gradient(to right top, transparent 50%, #000000 50%);;
          }

          &::after {
            bottom: 0;
            background: linear-gradient(to right bottom, transparent 50%, #000000 50%);;
          }
        }

        &.active {
          transition: background-position .2s ease-in-out;
          background-position: -100% 0;

          &.fade-out {
            transition: background-position $fade-transition linear;
            background-position: -200% 0;
          }
        }
      }
    }

    &.scroll-table {
      tbody tr {
        td:first-child {
          // untere Linie abschneiden
          &::before {
            position: absolute;
            content: '';
            display: block;
            width: $active-padding;
            height: 1px;
            left: 0;
            bottom: 0;
            background: $color-bg-dark;
            transform: translateY(1px);
          }
        }
      }
    }

    &.on-gray {
      tbody tr {
        @include media-breakpoint-down('sm') {
          td:last-child {
            &::before {
              background-image: linear-gradient(to left bottom, $color-bg-dark-lighter 50%, transparent 50%);;
            }

            &::after {
              background-image: linear-gradient(to right bottom, $color-bg-dark-lighter 50%, transparent 50%);;
            }
          }
        }

        @include media-breakpoint-up('md') {
          td:last-child {
            &::before {
              background: linear-gradient(to right top, transparent 50%, $color-bg-dark-medium 50%);;
            }

            &::after {
              background: linear-gradient(to right bottom, transparent 50%, $color-bg-dark-medium 50%);;
            }
          }
        }
      }
    }
  }



  // ------------ specific tables ------------


  // Aktienfinder: Table trends in header
  &.table-trends,
  &.table-fonds {
    min-height: 200px;

    th,
    td {
      &:last-child {
        width: 70px;
        text-align: right;

        span {
          &::after {
            content: '%';
          }
        }
      }
    }
  }
}

.table-wrap-compare {
  flex-grow: 1;
  min-height: 300px;
  padding-top: 50px;

  .table-compare {
    .sortable {
      color: white;
    }

    .current {
      position: absolute;
      width: 100%;
      margin-left: -10px;
      top: 0;
      border-top: 1px solid $color-divider;
      border-bottom: 1px solid $color-divider;
      background-color: $color-marker-compare;

      td {
        padding: 8px 15px 8px 6px;
        vertical-align: middle;

        &:first-child {
          padding: 0 16px 0 5px;
          line-height: 1.1;

          div {
            display: flex;
            align-items: center;
            height: 50px;
            overflow: hidden;
          }
        }

        &:last-child {
          padding-right: 18px;
        }
      }
    }

    tbody {
      top: 100px;
    }

    td, th {
      &:nth-child(n+2) {
        width: 60px;
        @include media-breakpoint-up('sm') {
          width: 100px;
        }
      }
    }
  }
}


// Aktienfinder: Table stocks in trends
.table-wrap-stocks-trends,
.table-wrap-fonds {
  overflow: visible;
  margin-bottom: 20px;

  table.bootstrap-table {
    transition: background-color .3s ease-in-out;
    table-layout: fixed;
    width: 100%;

    // table is fixed, so we must set min-widths to enable the horizontal scroll bar
    min-width: 720px;
    @include media-breakpoint-up('sm') {
      min-width: 760px;
    }

    td {
      vertical-align: top !important;
      word-wrap: break-word;
      hyphens: auto; // does not yet work on chrome windows :-(
    }

    tr {
      transition: background-color .3s ease-in-out;

      // row has visible charts
      &.show-chart {
        .link-and-chart .chart {
          .close-chart {
            display: block;
            text-align: center;
            font-size: 20px;
            line-height: 32px;
          }

          figure {
            filter: grayscale(100%);
            opacity: .4;
          }
        }

        td {
          border-color: transparent !important;
        }
      }

      // row with the 2 charts
      &.charts {
        td {
          padding: 0 !important;
          font-family: $font-family-base !important;

          .wrap {
            height: 0;
            padding: 0 20px;
            overflow: hidden;
            transition: height .3s ease-in-out;
          }
        }

        &.visible {
          td .wrap {
            height: 280px;
          }
        }

        .chart {
          width: 100%;
          height: 240px;
          margin: 10px 0 20px 0;
          background-color: #ffffff;

          .graph {
            border-color: $color-border-light;
          }

          .chart-perf {
            display: none;
          }

          .use-bg,
          .dygraph-axis-label-y {
            background-color: #ffffff;
          }
        }
      }
    }

    // container for link and mini chart
    .link-and-chart {
      position: relative;
      padding-right: 65px;

      .chart {
        position: absolute;
        top: -4px;
        right: 10px;
        width: 50px;
        height: 32px;
        //border: 1px solid $color-border-light;
        pointer-events: all;
        cursor: pointer;

        .dygraph-legend,
        .close-chart {
          display: none;
        }
      }
    }

    // table class when charts are visible
    &.view-charts {
      background-color: #efefef;

      tr.show-chart,
      tr.charts {
        background-color: #ffffff;
      }
    }
  }
}



.table-wrap-stocks-trends,
.table-wrap-fonds {
  table.bootstrap-table {
    tr {
      // column widths
      > :nth-child(2),
      > :nth-child(3),
      > :nth-child(5),
      > :nth-child(6) {
        width: 73px;
        text-align: right;
      }

      > td {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6) {
          padding-left: 0 !important;
          padding-right: 15px !important;
        }
      }
    }

    .cell-trend .chart-size-small {
      right: -60px;
    }
    th a {
      position: absolute;
      z-index: 1;
    }
  }
}


// specific stock table layout settings
.table-MainNumbersYear,
.table-BS_Overview,
.table-BS_1_2,
.table-BS_5,
#masterdata .data-table {
  td {
    font-size: 0.92rem;
  }
}

.table-BS_1_2,
.table-BS_5 {
  td:not(:first-child) {
    font-size: 0.88rem;
    line-height: 1.5;
  }
}

.table-MainNumbersYear,
#masterdata .data-table {
  td {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

#zoneCompareInstrument .data-table.active-rows {
  td a {
    font-size: 0.92rem;
  }

  tr.active {
    td:first-child {
      &::before {
        margin-top: -2px;
      }
    }
  }
}


// common bootstrap-table overrides
.bootstrap-table {
  a {
    color: $color-text-dark;
  }

  .fixed-table-toolbar {
    position: absolute;
    right: 0;
    top: -57px;
    z-index: 1;

    .form-control {
      width: 120px;
      padding-right: 20px;
      @include media-breakpoint-up('sm') {
        width: 200px;
      }
      height: auto;
      box-shadow: none;
      border-radius: 0;
      padding-bottom: 6px;
    }
  }

  .fixed-table-header {
    .table thead th {
      border-color: $color-border-light !important;
      border-bottom-width: 2px !important;
    }
  }

  .fixed-table-container {
    &.fixed-height .table thead th {
      border-color: transparent;
    }

    .table.table-sm {
      th {
        font-family: $font-family-extrabold;
        text-transform: uppercase;

        .th-inner {
          padding: 20px 5px;
        }

        &:first-child {
          .th-inner {
            padding-left: 0;
          }
        }
      }

      td {
        font-family: $font-family-bold;
        padding: 10px 5px;
        border: 0;
        border-bottom: 1px solid $color-border-light;

        &:first-child {
          padding-left: 0;
        }
      }

      tr:last-child td {
        border: 0;
      }
    }
  }

  .fixed-table-body {
    @include scroll-bar($track-color: rgba($color-text-light-medium, .2), $thumb-color: rgba($color-text-light-medium, .7));
  }

  .float-.pagination {
    display: flex;
    justify-content: center;
    margin: 30px 0 0 0;
  }
}

// arguments-/ analysis-tables TODO remove together with templates
.table-wrap-arguments-analyses {
  position: relative;

  .fa-search {
    position: absolute;
    right: 0;
    top: -35px;
    color: $color-text-light-medium;
  }

  .bootstrap-table {
    transition: background-color .3s ease-in-out;
    table-layout: fixed;
    width: 100%;

    .fixed-table-toolbar .form-control {
      width: 194px;
      color: $color-text-light;
      border-bottom-color: $color-divider;
      &:focus {
        border-bottom-color: $color-text-light-medium;
      }
    }

    // table is fixed, so we must set min-widths to enable the horizontal scroll bar

    .fixed-table-container .table.table-sm {
      min-width: 500px;

      td {
        vertical-align: top !important;
        word-wrap: break-word;
        hyphens: auto;
        padding: 5px;
      }

      tr {
        transition: background-color .3s ease-in-out;

        // column widths
        > :nth-child(1) {
          width: 130px;

        }

        > :nth-child(3) {
          width: 55px;
          text-align: right;
        }

        > :nth-child(4) {
          width: 140px;
          @at-root .page-home & {
            @include media-breakpoint-up('md') {
              width: 200px;
            }
          }
        }

        // data
        > td {
          &:nth-child(1),
          &:nth-child(3),
          &:nth-child(4) {
            font-family: $font-family-sans-serif;
          }

          &:nth-child(1) {
            padding-left: 0;
          }

          &:nth-child(3) {
            padding-right: 20px;
          }

          &:nth-child(1),
          &:nth-child(3) {
            color: $color-text-light-medium;
          }
        }
      }
    }
  }

  .bootstrap-table {
    color: $color-text-light;
    a {
      color: $color-text-light;
    }

    .fixed-table-header {
      .table thead th {
        border-color: $color-divider-dark !important;
        border-bottom-width: 2px !important;

        .th-inner {
          color: white;
        }
      }
    }

    .fixed-table-container {
      border-bottom-color: $color-divider-dark !important;
      .table.table-sm {
        td {
          border-color: $color-divider-dark;
        }
      }
    }

    .sortable {
      padding-right: 20px !important;

      &::after {
        background-position: 0 14px;
      }

      &.desc {
        &::after {
          background-position: 9px 14px;
        }
      }

      &.asc {
        &::after {
          background-position: 18px 14px;
        }
      }
    }

    .pagination {
      .page-link {
        color: #ffffff;
        background-color: transparent;
        &:hover {
          background: $color-bg-dark-lighter;
        }
      }
      .page-item {
        &.active {
          .page-link {
            background-color: $color-text-light;
            color: $color-text-dark;
          }
        }
      }
    }
  }
}
