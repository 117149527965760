// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------

// basic style for copy text
//
// example:
// https://github.com/HugoGiraudel/sass-boilerplate/blob/master/stylesheets/base/_typography.scss

.label {
  text-transform: uppercase;
  font-family: $font-family-extrabold;
  font-size: 1rem;
  line-height: 24px;
  margin: 0;
}

.body-text {
  font-family: $font-family-base;
  font-size: 1rem;
}

.text-color-medium {
  color: $color-text-light-medium;
}

hr {
  border-color: $color-divider;

  &.light {
    border-color: $color-border-light;
  }
}
