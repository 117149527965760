// -----------------------------------------------------------------------------
// Header of the site/application
// -----------------------------------------------------------------------------


$search-overlay-width: 500px;

.header {
  //$nav-width: 1060px;

  position: relative;

  // TRENDLINK logo
  .navbar-brand {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-family: $font-family-extrabold;
    font-size: 28px;
    line-height: 40px;
  }

  // main navigation
  .nav-item,
  .nav-item a,
  .user-image {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  // user entry
  .user-image {
    position: relative;
    margin: 0 10px 0 0;
    width: 48px;
    height: 48px;
    border-radius: 5px;
    justify-content: center;
    background-color: #777777;
    color: white;
    font-family: $font-family-extrabold;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  //@media (max-width: $nav-width) {
  //  .hidden-too-narrow {
  //    display: none;
  //  }
  //}

  // search open indicator
  @include media-breakpoint-up('md') {
    &::after {
      position: absolute;
      right: 10px;
      bottom: 20px;
      width: 0;
      height: 2px;
      background: $color-pos;
      transition: width .2s ease-in-out;
      content: '';
    }

    &.search-open::after {
      width: $search-overlay-width - 10px;
    }
  }
}

// nav menu
.navbar-collapse {
  @include media-breakpoint-down('sm') {
    background-color: $color-bg-dark-medium;
    margin-bottom: -10px;
    border-top: 10px solid white;
  }
}

// mobile toggle button
.navbar-toggler {
  position: absolute;
  top: 14px;
  right: 15px;
  width: 30px;
  height: 30px;
  border: 0;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  .bars {
    position: absolute;
    top: 13.5px;
    left: 3px;
    width: 21px;
    height: 4px;
    transition: all .24s;
    border-radius: 2px;
    outline: 1px solid transparent;
    background: transparent;

    &::before,
    &::after {
      display: block;
      position: absolute;
      top: 0;
      width: 24px;
      height: 4px;
      transition: all .2s;
      border-radius: 2px;
      outline: 1px solid transparent;
      background: #000000;
      content: '';
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &.collapsed .bars {
    left: 5px;
    background: #000000;

    &::before,
    &::after {
      width: 21px;
      transform: none;
    }

    &::before {
      top: -8px;
    }

    &::after {
      top: 8px;
    }
  }
}

// navigation
.navbar {
  display: block;
  text-align: center;
  padding: 10px 0;
  border-bottom: 0 none !important;
  margin-bottom: 0 !important;

  .navbar-nav {
    position: relative;
  }

  // icons in btn
  .icon-left {
    padding-left: 45px;
  }

  .nav-search .nav-link {
    width: 22px;
    height: 22px;
    font-size: 20px;
  }

  .search-container {
    input {
      border: 0;
      margin: 0;
      font-size: 22px;
    }
  }

  .nav-item .btn {
    display: block;
    width: 100%;
    @include media-breakpoint-up('sm') {
      display: inline-block;
      width: auto;
    }
  }

  @include media-breakpoint-down('sm') {
    .navbar-nav {
      display: block;
      padding: 10px 20px 20px 20px;
      margin-top: 60px;
    }

    .nav-item {
      display: block;

      .nav-link {
        display: block;
        padding: 10px 0;
        color: $color-text-light !important;
        text-align: left;
      }

      &:not(.nav-search):not(.nav-button):not(.nav-user) {
        &:hover,
        &.active {
          .nav-link {
            color: $color-text-light;
            font-family: $font-family-bold;

            &::before {
              content: '';
              display: inline-block;
              border: 4px solid transparent;
              border-left: 5px solid $color-text-light;
              margin: 0 0 1px -9px;
            }
          }
        }
      }
    }

    .nav-user {
      margin-top: 10px;
      border-top: 1px solid $color-divider;
      padding-top: 10px;

      #dropdown-user {
        pointer-events: none;
        position: absolute;

        .user-image {
          width: 60px;
          height: 60px;
          margin: 15px 0 0 0;
        }
      }

      .fa-angle-down {
        display: none;
      }

      .dropdown-menu {
        display: block;
        margin: 0 0 0 100px;
        padding: 0;
        background: transparent;
        border: none;
        box-shadow: none;
      }

      .dropdown-item {
        padding: 10px 0;
        color: $color-text-light;
        text-align: left;

        &:hover,
        &.active {
          color: $color-text-light;
          background: transparent;

          &::before {
            content: '';
            display: inline-block;
            border: 4px solid transparent;
            border-left: 5px solid $color-text-light;
            margin: 0 0 1px -9px;
          }
        }
      }
    }

    .nav-search {
      position: absolute;
      top: -60px;
      width: calc(100% - 40px);

      .nav-link {
        position: absolute;
        top: 8px;
        right: 0;
        opacity: .5;

        .fa-search {
          pointer-events: none;
        }

        .fa-times {
          display: none;
        }

        @at-root .typeahead-open & {
          .fa-search {
            display: none;
          }

          .fa-times {
            display: block;
            margin: 3px 0 0 9px;
          }
        }
      }

      input {
        line-height: 60px;
        color: $color-text-light;
        border-bottom: 1px solid $color-divider;

        &:focus {
          border-bottom: 2px solid $color-pos;
        }
      }
    }

    .nav-button {
      margin-top: 10px;
      border-top: 1px solid $color-divider;
      padding-top: 20px;
    }
  }

  @include media-breakpoint-up('md') {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    min-height: 110px;
    border-bottom: 1px solid #000000;
    margin-bottom: 40px;

    .navbar-brand {
      margin-right: 20px;
      font-size: 38px;
    }

    .navbar-nav {
      align-items: center;
    }

    .nav-item {
      & + .nav-item {
        margin-left: 30px;
      }

      .nav-link {
        padding: calc-rem(1px) 0;
        color: #000000;
        border-bottom: 2px solid transparent;

        &:hover {
          border-color: #000000;
        }
      }

      &.active {
        .nav-link {
          border-color: #000000;
        }
      }
    }

    // user entry
    .nav-user {
      margin-left: 60px !important;
      svg {
        transition: transform .2s;
      }
      &::before {
        display: block;
        position: relative;
        left: -30px;
        width: 1px;
        height: 30px;
        background-color: $color-border-light;
        content: '';
      }
      &.show {
        svg {
          transform: rotateX(180deg);
        }
      }

      > a {
        color: $color-text-medium;
        text-decoration: none;
      }
    }

    .nav-search .nav-link {
      width: 30px;
      height: 60px;
      font-size: 20px;
      border: 0;
      background: #ffffff;
      z-index: 1001;
      transition: all .2s ease-in-out;
      svg {
        position: absolute;
        right: 0;
        transition: opacity .2s ease-in-out;
      }

      .fa-times {
        transition: right .2s;
        right: 0;
        opacity: 0;
      }

      @at-root .search-open & {
        background: transparent;
        .fa-search {
          opacity: 0;
        }

        .fa-times {
          opacity: 1;
        }
      }

      @at-root .typeahead-open & {
        color: #ffffff;

        .fa-times {
          right: 20px;
        }
      }
    }

    .search-container {
      position: absolute;
      overflow: hidden;
      transition: all .2s ease-in-out;
      width: 0;
      min-width: 0;
      height: 60px;
      right: 0;
      background: white;

      .typeahead__container {
        position: absolute;
        width: 0;
        right: 0;
        background: white;
        transition: all .2s ease-in-out;
      }

      input {
        padding: 10px;
        width: 100%;
        height: 60px;
        line-height: 40px;
        transition: all .2s ease-in-out;
        opacity: 0;
      }

      @at-root .search-fade & {
        overflow: hidden !important;
      }

      @at-root .search-open & {
        width: 100%;
        min-width: $search-overlay-width;
        overflow: visible;
        .typeahead__container {
          width: $search-overlay-width;
        }
        input {
          opacity: 1;
        }
      }

      @at-root .typeahead-open & {
        .typeahead__container {
          width: 750px;
          z-index: 1000;

          input {
            padding-left: 20px;
            color: #ffffff;
          }
        }
        .typeahead__query {
          background: #000000;
        }
      }
    }
  }

  // add some extra room for ETF/Fonds nav entry
  @media (min-width: 768px) and (max-width: 864px) {
    .nav-item {
      & + .nav-item {
        margin-left: 20px;
      }
      &.nav-search {
        margin-left: 0;
      }
      &.nav-user {
        margin-left: 30px !important;
        &::before {
          left: -15px;
        }
      }
    }
  }
}

// FIXME: remove after implementation
body:not(.logged-in) {
  .hidden-logged-out {
    display: none !important;
  }
}

body.logged-in {
  .hidden-logged-in {
    display: none !important;
  }
}
