// -----------------------------------------------------------------------------
// Tab area below stock and trend headers
// -----------------------------------------------------------------------------

.data-tabarea {
  @extend %container-padding-40-40;
  padding-top: 10px;
  border-top: 1px solid $color-divider;
  background-color: $color-bg-dark-medium;
  color: $color-text-light;

  .heading-addition {
    color: $color-text-light-medium;
    @include media-breakpoint-only('xs') {
      display: block;
      margin: -17px 0 10px 0;
    }
  }

  textarea {
    width: 100%;
    height: 100%;
    background: transparent;
    color: $color-text-light;
  }

  .redactor-box {
    background-color: transparent;
  }

  .redactor-toolbar a, .redactor-air a {
    background-color: $color-bg-dark;
  }

  .redactor-statusbar {
    background-color: $color-bg-dark;
    border-color: transparent;
  }

  .tab-content {
    padding-top: 40px;

    .label {
      margin-bottom: 15px;
    }
  }

  .chart-container {
    height: 100%;
    min-height: 150px;
  }

  .newsletter-box {
    margin-top: 39px;
    height: 100%;
    padding: 20px;
    border-color: $color-border-light;
  }

  .label-legend {
    position: absolute;
    top: 19px;
    font-size: calc-rem(13px);
    color: $color-text-light-medium;
  }
}
