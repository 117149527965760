// -----------------------------------------------------------------------------
// Social share and social links buttons
// -----------------------------------------------------------------------------

.social-share,
.social-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    display: block;
    & + li {
      padding-left: 15px;
    }

    a {
      display: block;
      color: $color-text-light-medium;
      font-size: 24px;
      line-height: 24px;
      height: 24px;

      &:hover {
        color: $color-text-dark;
      }
    }
  }
}

// on dark backgrounds
#dialog-analysis,
.analysis-arguments .argument.open,
.bg-gray,
.bg-black,
.bg-blue {
  .social-share,
  .social-links {
    li a:hover {
      color: $color-text-light;
    }
  }
}

