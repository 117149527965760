// -----------------------------------------------------------------------------
// Inline redactor editor
// -----------------------------------------------------------------------------

// editor controls edit/save/cancel
.edit-links {
  a {
    font-family: $font-family-sans-serif;
    font-size: calc-rem(16px);
    white-space: nowrap;
    & + a {
      margin-left: 10px;
    }
  }

  .save,
  .cancel {
    display: none;
  }
}

// container
.inline-editor {
  textarea {
    width: 100%;
    height: 100%;
  }
}
