// -----------------------------------------------------------------------------
// Page: Aktienportrait
// -----------------------------------------------------------------------------

.stock-header {
  padding-bottom: 10px;
  @include media-breakpoint-down('sm') {
    .label {
      text-align: center;
    }
  }

  .wrap {
    @include media-breakpoint-up('md') {
      display: grid;
      grid-template-columns: 50% 50%;
    }
  }

  .name {
    padding: 0 20px 20px 0;

    .meta {
      margin: 0;
      .flex-shrink-1 {
        white-space: normal;
      }
    }
  }

  .chart-container {
    margin-bottom: 20px;
    @include media-breakpoint-up('md') {
      grid-row: 1 / 3;
      grid-column: 2;
      padding: 0 0 39px 0;
      margin-left: 20px;
    }

    .chart {
      height: 50vw;
      @include media-breakpoint-up('md') {
        height: 100%;
      }
    }
  }

  .additional-trends {
    @include media-breakpoint-up('md') {
      grid-row: 2;
      grid-column: 1;
      margin-right: 20px;
      padding-top: 20px;
      border-top: 1px solid $color-divider;
    }
  }

  .slider {
    margin: 10px 0;
  }

  .row.sliders {
    .slider {
      margin-bottom: 0;
      @include media-breakpoint-up('lg') {
        margin-bottom: -20px;
      }
    }
  }

  .analysis-info {
    .label {
      margin-bottom: 20px;
    }

    .meta {
      @include media-breakpoint-up('md') {
        display: block;
        .vote {
          margin-right: 20px;
        }
      }
    }
  }
}

.page-stock {

  .edit-links {
    display: inline-block;
    margin-bottom: 1rem;
  }

  .listview-header {
    &.stocks,
    &.arguments {
      @include media-breakpoint-up('md') {
        margin-top: -20px;
      }
    }
  }

  .stock-comments .comments {
    @include media-breakpoint-up('md') {
      bottom: 20px;
    }
  }

  .data-table.compact {
    font-size: 14px;
  }

  #figures {
    tr:nth-child(8) td {
      border-top: 1px solid $color-divider;
    }
  }
}

