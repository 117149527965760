// -----------------------------------------------------------------------------
// Page: Fondfinder
// -----------------------------------------------------------------------------

.stock-finder-header.fonds-finder-header {

  .intro-head {
    &::after {
      margin-bottom: 20px;
    }
  }
}

.page-fonds-finder {
  .content-box {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 20px;
    @include media-breakpoint-up('lg') {
      padding-bottom: 30px;
    }

    .fa-search {
      position: absolute;
      top: 25px;
      right: 20px;
      @include media-breakpoint-up('lg') {
        right: 40px;
      }
      pointer-events: none;
      color: $color-text-light-medium;
    }
  }

  .footnote {
    @extend %container-padding-20-40;
    margin-bottom: 10px;
    font-size: $font-size-sm;
  }
}
