// -----------------------------------------------------------------------------
// Newsletter subscribe box
// -----------------------------------------------------------------------------

.newsletter-box {
  @extend %container-padding-20-40;
  border: 1px solid #000000;
  @include media-breakpoint-down('sm') {
    border-left: 0;
    border-right: 0;
  }
}
