// -----------------------------------------------------------------------------
// Page: Übersicht Analysen
// -----------------------------------------------------------------------------

// responsive list block
.arguments-analysis-list {
  @extend %container-padding-20-40;
  padding-top: 0;
  margin-top: 45px;
  height: calc(100% - 45px);
  color: $color-text-light;

  @include media-breakpoint-down('sm') {
    .listview-header {
      border: 0;
      margin-bottom: 30px;
    }
  }

  .table-wrap-arguments {
    position: relative;
  }

  uul {
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 2px solid $color-divider-dark;

    li {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid $color-divider-dark;

      > * {
        flex: 1;
        //display: table-cell;
        vertical-align: top;
        padding: 5px 0;

        &:nth-child(1) {
          flex: 0 0 145px;
        }

        &:nth-child(2) {
          flex-grow: 1;
        }

        &:nth-child(3) {
          flex: 0 0 60px;
          text-align: right;
        }

        &:nth-child(4) {
          flex: 0 0 150px;
          padding-left: 20px;
        }

        @include media-breakpoint-down('xs') {
          &:nth-child(1) {
            flex-basis: 100%;
          }
          &:nth-child(4) {
            flex-basis: 100%;
            text-align: right;
          }
        }
        @include media-breakpoint-only('md') {
          &:nth-child(1) {
            flex-basis: 100%;
          }
          &:nth-child(4) {
            flex-basis: 100%;
            text-align: right;
          }
        }
      }

      .vote,
      .date {
        white-space: nowrap;
      }

      .date {
        color: $color-text-light-medium;
      }
    }

    .link,
    .author {
      color: $color-text-light;
    }

    .link {
      font-family: $font-family-bold;
      word-break: break-word;
      hyphens: auto;
    }
  }
}

.page-analyses {

  .analysis-intro {
    height: 100%;
  }

  .social-links-box {
    @extend %container-padding-20-40;
    text-align: center;

    .social-links {
      display: inline-flex;
    }
  }
}
