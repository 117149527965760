// -----------------------------------------------------------------------------
// Modal dialogs
// -----------------------------------------------------------------------------

.modal-dialog {
  @include media-breakpoint-down('xs') {
    margin: 0;
  }
}

.modal-header {
  padding: 20px;
  min-height: 80px;
  background-color: $color-bg-dark;
  align-items: center;
  border: 0;

  .modal-title {
    position: absolute;
    width: calc(100% - 2rem);
    text-align: center;
  }

  .button-close {
    margin-left: auto;
    width: 42px;
    height: 42px;
    line-height: 39px;
    text-align: center;
    border: 2px solid $color-bg-dark-medium;
    border-radius: 50%;
    background-color: $color-bg-dark-medium;
    color: $color-text-light;
    z-index: 1;

    &:hover {
      background-color: $color-bg-dark-lighter;
    }
  }
}

.modal-title {
  text-transform: uppercase;
  font-family: $font-family-bold;
  font-size: 17px;
  @include media-breakpoint-up('sm') {
    font-size: 20px;
  }
  color: $color-text-light;
}

.modal-content {
  position: relative;
  background-color: $color-bg-dark-medium;
  color: $color-text-light;
  @include media-breakpoint-down('xs') {
    max-height: 100vh;
  }
}

.modal-footer {
  border-color: $color-divider;
  padding: 20px;

  &.justify-content-center {
    button {
      @include media-breakpoint-down('sm') {
        width: 100%;
      }
    }
  }
}

form.modal-content {
  .modal-body {
    padding: 20px;
  }

  @include media-breakpoint-up('md') {
    .modal-body {
      padding: 40px;
    }
  }
}
