// -----------------------------------------------------------------------------
// Chart containers and graphs
// -----------------------------------------------------------------------------

// common
.chart {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 200px;

  .graph {
    position: absolute;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid $color-divider;
  }

  .perc {
    position: absolute;
    top: -12px;
    right: 25px;
    padding: 0 5px;
    background-color: #ffffff;
  }

  .xline {
    position: absolute;
    display: none;
    top: 20px;
    width: 1px;
    height: calc(100% - 50px);
    background-color: red;
  }

  .dygraph-axis-label {
    color: $color-text-light-medium;
    font-size: calc-rem(13px);
  }

  .dygraph-axis-label-x {
    padding: 4px 0;
  }

  .dygraph-axis-label-y {
    position: absolute;
    right: 7px;
    top: -4px;
    width: auto;
    padding: 2px 0;
    text-align: right;
    //margin: -4px 0 0 -20px;
  }

  .chart-legend,
  .chart-perf,
  .chart-title {
    position: absolute;
    padding: 0 4px;
    top: -7px;
    right: 16px;
    line-height: 16px;

    .date {
      color: $color-text-light-medium;
    }

    .p {
      display: inline-block;
      width: 52px;
      text-align: right;
      font-family: $font-family-bold;
    }
  }

  .chart-perf {
    z-index: 1;
  }

  .chart-legend {
    z-index: 2;
  }

  .chart-title {
    z-index: 1;
    left: 16px;
    right: auto;
    font-family: $font-family-bold;
  }

  // annotation bubble
  .annotation-analysis {
    $size: calc-rem(22px);
    display: block;
    border-radius: 50%;
    // following are fixed via Dygraphs js as style attributes :-( It's 2020 folks...
    width: $size !important;
    height: $size !important;
    border-color: transparent !important;
    box-shadow: 0 0 4px -1px rgba(0, 0, 0, .4);
    cursor: default;
  }
}

// small chart in sliders
.chart-size-small {
  pointer-events: none;
  min-height: 0;

  .graph {
    width: 100%;
    height: 100%;
    border: 0;
  }

  .analysisAnnotation {
    display: none; // TODO it would be more performant to just not render the annotation in the the first place...
  }
}

// medium and large charts
.chart-size-medium,
.chart-size-large {
  position: relative;
  min-height: 0;
}

// large header charts
.chart-size-large {
  .container-control {
    padding-top: 8px;
    visibility: hidden;
  }

  &.loaded {
    .container-control {
      visibility: visible;
    }
  }

  .graph {
    top: 47px;
  }

  .chart-legend, .chart-perf {
    top: 40px;
  }
}

.chart-dropdown {
  .graph {
    top: 39px;
  }
}

// annotations
.popover.chart-annotation {
  .popover-body {
    line-height: 1;

    .item {
      margin-top: 7px;
      > a {
        display: inline-block;
        margin-top: 5px
      }
    }

    .date {
      font-size: 12px;
      color: $color-text-medium;
    }
    .author a {
      color: $color-text-medium;
    }
  }

  // save some mouseover space
  .hover-catch {
    position: absolute;
    left: 50%;

    &::after {
      display: block;
      position: absolute;
      width: 50px;
      height: calc-rem(30px);
      bottom: calc-rem(-30px);
      transform: translateX(-50%);
      content: '';
    }
  }

  &.bs-popover-bottom {
    .hover-catch {
      top: -22px;
    }
  }
}

[data-chart-type="2"] .graph {
  // The RGraph charts can draw their own border but it is also dashed/dotted if the grid is :-(
  border: 1px solid $color-divider;
}

// The y-axis labels are moved over the axis. Therefore we give it a little contrast.
.rgraph_accessible_text_xaxis_labels,
.rgraph_accessible_text_yaxis_labels {
  background-color: $color-bg-dark-medium;
  padding: 1px 3px;
}

.tab-pane .chart-container {
  margin-bottom: 40px;
}

#tl-rgraph-tooltip {
  position: absolute;
  color: #fff;
  background-color: #000;
  font-size: 85%;
  padding: 6px;
  white-space: nowrap;
  border-radius: 4px;
  box-shadow: rgba(96, 96, 96, .5) 0 0 5px;
}
