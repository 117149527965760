// -----------------------------------------------------------------------------
// Page: Benutzerregistrierung
// -----------------------------------------------------------------------------

.page-register {

  .col-register,
  .col-login {
    display: flex;
    flex-direction: column;
  }

  .content-box {
    flex-grow: 1;
  }

  .social-login {
    .btn {
      margin-top: 20px;
    }
  }

  @include media-breakpoint-down('md') {
    .col-register {
      order: 2;
    }
    .col-login {
      order: 1;
    }
  }

  @include media-breakpoint-between('sm', 'md') {
    .user-login,
    .social-login {
      display: flex;
      flex-flow: column;
      > :first-child {
        display: block;
        margin-bottom: auto;
        padding-bottom: 20px;
      }
      > .form-group:last-child {
        padding-bottom: 0;
      }
    }
    .user-login {
      position: relative;
      padding-right: 20px;

      &::after {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        width: 1px;;
        height: 100%;
        background: $color-divider;
      }
    }

    .social-login {
      padding-left: 20px;
    }
  }

  .password-lost {
    margin-left: 10px;
    float: right;
  }

  @include media-breakpoint-up('lg') {
    .col-login {
      form {
        display: flex;
        flex-flow: column;

        .row {
          flex-grow: 1;

          .social-login {
            margin-top: auto;
          }
        }
      }
    }
  }
}
