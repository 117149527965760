// -----------------------------------------------------------------------------
// Meta information before and after content
// -----------------------------------------------------------------------------

.meta {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  font-size: calc-rem(14px);
  line-height: 20px;
  margin: 0;
  color: $color-text-light-medium;

  a {
    text-decoration: underline;
  }

  a:not(.dropdown-item) {
    color: $color-text-light-medium;
  }
  .vote > a {
    color: $color-vote-dark;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }

  &.no-margin {
    margin: 0 !important;
  }

  h1 + &:last-child,
  h2 + &:last-child,
  h3 + &:last-child,
  h4 + &:last-child {
    margin-top: 0;
  }

  .author {
    color: $color-text-light-medium;
    margin-right: 10px;
  }

  .dropdown-toggle {
    &::after {
      content: none;
    }
  }

  .dropdown-menu {
    font-size: calc-rem(14px);
    a {
      text-decoration: none;
    }
  }

  > * {
    display: inline-block;
    white-space: nowrap;
  }

  > :last-child .dropdown-menu {
    left: auto !important;
    top: auto !important;
    transform: none !important;
    right: -20px;
    @include media-breakpoint-up('md') {
      right: -40px;
    }
  }

  > :first-child .dropdown-menu {
    left: -20px;
    @include media-breakpoint-up('md') {
      left: -40px;
    }
  }

  .dropdown.actions {
    position: relative;
    margin: -10px -8px -5px 0;
  }
}
