// -----------------------------------------------------------------------------
// Comment block
// -----------------------------------------------------------------------------

.comments {

  @include media-breakpoint-down('sm') {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background-color: $color-bg-lightest;
    z-index: 2;
    &.open {
      display: block;
    }
    .listview-header {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1;
    }
  }

  @include media-breakpoint-up('md') {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 10px;
    bottom: 0;
    left: 10px;
    overflow: hidden;
    .list {
      overflow: auto;
    }
  }

  .listview-header {
    flex-shrink: 0;
    & + p {
      margin: 15px auto;
      text-align: center;
    }
  }

  .single {
    display: none;
  }

  .editor {
    @extend %container-padding-20-40;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $color-bg-dark-medium;
    color: $color-text-light;
  }

  textarea.form-control {
    width: 100%;
    min-height: 80px;
    margin: 10px 0 15px 0;
    padding: 5px 20px 5px 5px;
    background: $color-bg-dark-medium;
    border: 1px solid transparent;
    border-bottom-color: $color-divider;
    color: $color-text-light;
    font-size: calc-rem(14px);

    &:focus {
      outline: none;
      border-color: $color-divider;
      border-bottom: 2px solid $color-border-light;
    }
  }

  .submit-comment {
    position: absolute;
    right: 17px;
    @include media-breakpoint-up('lg') {
      right: 37px;
    }
    bottom: 0;
    border: 0;
    background-color: transparent;
    color: $color-text-light;
    font-size: calc-rem(25px);
  }

  > .list {
    @extend %container-padding-20-40;
    height: 100%;
    border: 1px solid $color-border-light;

    .show-more {
      margin: 20px 0 0 50%;
      transform: translateX(-50%);
      //display: none;
    }
  }

  .comment {
    .content {
      padding: 10px;
      background-color: $color-bg-light;
      font-size: calc-rem(14px);

      :last-child {
        margin-bottom: 0;
      }

    }

    .dropdown-menu.voters {
      right: auto;
      left: -20px !important;
    }

    .dropdown.actions {
      .dropdown-menu {
        right: 0;
      }
    }

    & + .comment {
      margin-top: 30px;
    }

    .meta .author {
      margin: 0;
    }

    &.hidden {
      .meta > :not(.actions),
      .content {
        opacity: .25;
        filter: blur(2px);
      }
    }
  }
}

// open and close comment popover buttons for mobile
.open-comments {
  position: absolute;
  right: 20px;
  bottom: -25px;
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50%;
  background-color: $color-bg-dark-medium;
  color: $color-text-light;
  font-size: 22px;
  line-height: 53px;
  box-shadow: 0 5px 12px -5px rgba(0, 0, 0, 0.7);
  z-index: 1;
  @include media-breakpoint-up('md') {
    display: none;
  }
}

.close-comments {
  $round-button-size-small: 30px;
  position: absolute;
  top: 16px;
  right: 20px;
  width: $round-button-size-small;
  height: $round-button-size-small;
  line-height: $round-button-size-small - 5px;
  @include media-breakpoint-up('md') {
    display: none;
  }
  color: $color-text-light;
  border: 2px solid $color-bg-dark-medium;
  border-radius: 50%;
  background-color: $color-bg-dark-medium;
}

