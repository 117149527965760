// -----------------------------------------------------------------------------
// Footer of the site/application
// -----------------------------------------------------------------------------

.footer {
  background-color: #000000;
  color: $color-text-light;
  padding: 20px;

  @include media-breakpoint-up('md') {
    padding: 40px 0;
  }

  .about {
    max-width: 600px;
  }

  hr {
    @include media-breakpoint-up('md') {
      margin: 40px 0;
    }
  }

  .links {
    font-size: 14px;
    text-align: center;
    @include media-breakpoint-up('md') {
      text-align: left;
    }
    a {
      color: #ffffff;
      & + a {
        &::before {
          display: inline-block;
          margin: 0 5px;
          vertical-align: text-bottom;
          content: '·';
        }
      }
    }
  }

  .copyright {
    padding-top: 20px;
    @include media-breakpoint-up('xl') {
      padding-top: 0;
    }
    text-align: center;
  }

  .social {
    padding-top: 20px;
    @include media-breakpoint-up('xl') {
      padding-top: 0;
    }
    text-align: center;
    .social-links {
      display: inline-flex;
      a {
        color: $color-text-light;
        &:hover {
          color: $color-text-light-medium;
        }
      }
    }
  }

  .newsletter-box {
    padding: 0;
    margin: 20px 0 10px 0;
    @include media-breakpoint-up('sm') {
      margin: 0;
      width: 260px;
    }
     h3 {
      margin-top: -3px;
    }
  }
}
