// -----------------------------------------------------------------------------
// Dropdowns
// -----------------------------------------------------------------------------

// common
.dropdown {
  .dropdown-item.active {
    font-family: $font-family-bold;
  }

  .dropdown-menu.nav {
    display: none;

    &.show {
      display: block;
    }
  }

  // as control for interactive container contents
  &.container-control {
    .dropdown-toggle {
      display: inline-block;
      padding-bottom: 14px;
      margin: 0;
      border-bottom: 2px solid $color-border-light;
      color: inherit;
      text-decoration: none;
      text-transform: uppercase;
      font-family: $font-family-extrabold;
      font-size: 1rem;
      line-height: 24px;
      z-index: 1;

      &::after {
        content: none;
      }

      svg {
        display: inline-block;
        margin-left: 10px;
        transition: transform .2s;
      }
    }

    &.show {
      svg {
        transform: rotateX(180deg);
      }
    }

    .dropdown-menu {
      margin-top: -2px;
    }
  }

  // action menus
  &.actions {
    .btn-actions {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      padding: 0;
      box-shadow: none;
      border-radius: 50%;

      svg {
        margin: 0;
      }
    }

    .dropdown-menu {
      svg {
        margin-left: -10px;
        margin-right: 5px;
      }
    }
  }
}
