// -----------------------------------------------------------------------------
// Company engagement bars
// -----------------------------------------------------------------------------

.bars {
  font-family: $font-family-extrabold;
  font-size: 1rem;
  letter-spacing: .09rem;
  line-height: 24px;
  text-transform: uppercase;

  &::before {
    color: $color-text-dark;
  }

  &::after {
    color: $color-border-light;
  }

  &.bars1 {
    &::before {
      content: 'i'
    }

    &::after {
      content: 'ii'
    }
  }

  &.bars2 {
    &::before {
      content: 'ii'
    }

    &::after {
      content: 'i'
    }
  }

  &.bars3 {
    &::before {
      content: 'iii'
    }

    &::after {
      content: none;
    }
  }
}

.stock-list {
  .stock .bars {
    font-size: 13px;
    line-height: 13px;
  }
}

.bg-blue,
.bg-black,
.bg-gray,
.stock-list div.stock:nth-of-type(even) {
  .bars {
    &::before {
      color: $color-text-light;
    }

    &::after {
      color: $color-text-light-medium;
    }
  }
}
