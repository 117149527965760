// -----------------------------------------------------------------------------
// Details block at page end, full page width
// -----------------------------------------------------------------------------

.details {
  padding: 20px;
  @include media-breakpoint-up('md') {
    padding: 40px 0;
  }
  background-color: $color-bg-light;

  .content {
    padding-top: 20px;
    @include media-breakpoint-up('md') {
      padding-top: 30px;
    }
  }
}
