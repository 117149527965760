// -----------------------------------------------------------------------------
// Author summary block
// -----------------------------------------------------------------------------

.author-summary {
  padding: 20px;
  @include media-breakpoint-up('md') {
    padding: 40px 0;
  }
  background-color: $color-bg-light;

  .profile {
    padding: 20px 0;
    @include media-breakpoint-up('md') {
      padding: 40px 0;
    }
  }

  .person {
    white-space: nowrap;
    position: relative;
    margin: 0 auto;
    flex-direction: column;
    min-height: 70px;
    padding-left: 90px;
    @include media-breakpoint-up('sm') {
      padding-left: 120px;
      min-height: 100px;
    }
    @include media-breakpoint-up('md') {
      padding-right: 20px;
    }
  }

  .name {
    font-family: $font-family-bold;
    margin-bottom: 0;
  }

  .image {
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 70px;
    height: 70px;
    border-radius: 10px;
    justify-content: center;
    color: white;
    font-family: $font-family-extrabold;
    font-size: 22px;
    line-height: 70px;
    @include media-breakpoint-up('sm') {
      width: 100px;
      height: 100px;
      line-height: 100px;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .text {
    padding-top: 20px;
    @include media-breakpoint-down('sm') {
      margin: 20px 0 0 0;
      border-top: 1px solid $color-border-light;
    }
    @include media-breakpoint-up('md') {
      padding: 0 10px 0 20px;
      border-left: 1px solid $color-border-light;
    }

    > :not(.link-) {
      flex: 0 0 100%;

      &:empty {
        display: none;
      }
    }

    .link- {
      @include media-breakpoint-down('sm') {
        margin: 5px 0 5px auto;
      }
    }
  }

  .label {
    @include media-breakpoint-down('sm') {
      text-align: center;
    }
  }
}
