// -----------------------------------------------------------------------------
// Page: Searchresults
// -----------------------------------------------------------------------------

.page-search-results {

  h2 {
    padding-bottom: 20px;
    margin: 20px 0;
    border-bottom: 1px solid $color-bg-dark
  }

  ul + h2 {
    margin-top: 40px;
    @include media-breakpoint-up('md') {
      margin-top: 60px;
    }
  }

  .results {
    list-style: none;
    padding: 0;

    li {
      a {
        display: block;
        padding: 5px 0;
        color: $color-text-dark;
        svg {
          display: none;
        }
        &:hover {
          svg {
            display: inline;
          }
        }
      }
    }

    .title {
      font-family: $font-family-bold;

      &::after {
        content: '-';
        margin: 0 8px;
        display: inline-block;
      }
    }
  }

}
