// -----------------------------------------------------------------------------
// Tab-bar / tab nav control
// -----------------------------------------------------------------------------

.tab-nav {
  flex-wrap: nowrap;
  li {
    position: relative;

    & + li {
      margin-left: 40px;
      @include media-breakpoint-only('xs') {
        margin-left: 20px;
      }

      &::before {
        position: absolute;
        width: 1px;
        height: 20px;
        left: -20px;
        @include media-breakpoint-only('xs') {
          left: -10px;
        }
        top: 10px;
        background-color: $color-divider;
        content: '';
      }
    }

    a {
      display: block;
      padding: 10px 0 16px 0;
      border-bottom: 2px solid transparent;
      font-family: $font-family-bold;
      line-height: 20px;
      text-transform: uppercase;
      color: $color-text-medium;
      text-decoration: none;

      &.active {
        color: $color-text-light;
        border-color: $color-pos;
      }

      @include media-breakpoint-only('xs') {
        font-size: calc-rem(15px);
      }
    }
  }

  // tabs as container content switcher
  &.container-control {
    li {
      a {
        padding-top: 2px;
      }

      & + li {
        &::before {
          top: 2px;
        }
      }
    }
  }

  // on white background
  &.on-white {
    li {
      a {
        color: $color-text-light-medium;

        &.active {
          color: $color-text-dark;
        }
      }

      & + li {
        &::before {
          background-color: $color-text-light-medium;
        }
      }
    }
  }
}

// inside a listview header
.listview-header .tab-nav {
  display: inline-flex;
  margin: 5px 0 -21px 0;
  li {
    a {
      font-family: $font-family-extrabold;
      padding-bottom: 20px;
    }

    & + li {
      &::before {
        top: 12px;
      }
    }
  }

  @include media-breakpoint-up('md') {
    padding-left: 10px;
    margin: 30px auto 0 0;
    li {
      a {
        font-size: 1rem;
        line-height: 24px;
        padding-bottom: 39px;
      }
    }
  }
}
