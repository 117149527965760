// -----------------------------------------------------------------------------
// Page header breadcrumb
// -----------------------------------------------------------------------------

.breadcrumb {
  padding: 0;
  margin: 0;
  background-color: transparent;

  a {
    color: $color-text-light;
  }
}

.breadcrumb-item {
  @include media-breakpoint-down('sm') {
    i, svg {
      margin-right: 10px;
    }
  }
  @include media-breakpoint-up('md') {
    font-family: $font-family-bold;
    font-size: calc-rem(20px);
  }

  &.active {
    color: $color-text-light-medium;
  }

  [data-toggle='popover'] svg {
    margin: 0 !important;
  }
}

.breadcrumb-item + .breadcrumb-item {
  padding: 0;

  i, svg {
    font-size: calc-rem(18px);
    margin: 2px 15px 0 15px;
    color: $color-text-light;
  }

  &::before {
    content: none;
  }
}
