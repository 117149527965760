// -----------------------------------------------------------------------------
// Header element with headline and list controls
// -----------------------------------------------------------------------------

.listview-header {
  position: relative;
  text-align: center;
  padding: 20px 0;

  &:not(.no-border) {
    border-bottom: 1px solid $color-bg-dark-medium;
  }

  @include media-breakpoint-up('md') {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding: 0;
    height: 105px;
  }

  &.sm-flex {
    @include media-breakpoint-down('sm') {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      padding: 20px;
    }
  }

  font-family: $font-family-bold;
  font-size: calc-rem(14px);

  .dropdown-toggle {
    color: #000000;

    &::after {
      content: none;
    }

    > svg {
      margin-left: 5px;
      transition: transform .2s;
    }
  }

  .dropdown.show {
    .dropdown-toggle {
      > svg {
        transform: rotateX(180deg);
      }
    }
  }

  .chartswitch {
    color: $color-text-light-medium;

    .dropdown-toggle {
      color: $color-text-dark;
      text-decoration: none;
    }

    @include media-breakpoint-up('md') {
      .dropdown-toggle {
        display: none;
      }

      .dropdown-menu {
        display: inline !important;
        position: relative !important;
        white-space: nowrap;
        top: auto;
        margin: 0;
        padding: 0;
        border: 0;
        font-size: inherit;
        z-index: auto;
        float: none;
        min-width: 0;
        box-shadow: none;

        a {
          display: inline-block;
          padding: 0;
          width: auto;

          &.active,
          &:hover {
            background-color: transparent;
          }

          & + a {
            &::before {
              content: '|';
              display: inline-block;
              margin: 0 5px;
            }
          }
        }
      }
    }
  }

  &:not(.sm-flex) {
    @include media-breakpoint-down('sm') {
      .chartswitch {
        color: $color-text-light-medium;
        position: absolute;
        top: 21px;
        right: 20px;
      }
    }
  }

  .sortswitch {
    margin-top: 3px;
  }
}
