// -----------------------------------------------------------------------------
// Sliders
// -----------------------------------------------------------------------------

// common
.slider {
  &:not(.initialized) {
    height: 100px;
    overflow: hidden;
  }

  .slides {
    overflow: hidden;

    > ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      transition: transform .3s;

      li {
        display: block;
        position: relative;
        //flex: 1;
        margin: 0;
        padding: 0;
      }
    }

  }

  &.no-anim {
    .slides > ul {
      transition: none;
    }
  }

  .controls {
    padding-top: 10px;
    text-align: center;

    button {
      border: 0;
      width: 20px;
      height: 20px;
      line-height: 20px;
      background: transparent;
      color: $color-text-dark;

      &:focus {
        outline: none;
      }

      &.disabled {
        pointer-events: none;
        color: $color-text-light-medium;
      }
    }

    @include media-breakpoint-up('md') {
      padding-top: 20px;
      text-align: left;
      margin-left: -8px;
    }
  }

  // specific
  &.trends,
  &.arguments,
  &.stocks {
    .slide {
      display: block;
      position: relative;
      width: 155px;
      border: 1px solid $color-border-light;
      transition: border-color .2s ease-in-out;
      margin: 10px 20px 5px 0;
      padding: 15px;

      &:hover {
        border-color: $color-divider;
      }

      .count,
      .perf,
      .title,
      .marker {
        position: absolute;
        top: -5px;
        font-size: calc-rem(12px);
        line-height: 11px;
        outline: 3px solid $color-text-light;
      }

      .count,
      .marker {
        left: 15px;
      }

      .marker {
        width: 10px;
        height: 10px;

        &.pos {
          background-color: $color-pos;
        }

        &.neg {
          background-color: $color-neg;
        }
      }

      .count,
      .perf,
      .title {
        background-color: $color-text-light;
      }

      .perf {
        top: auto;
        bottom: -7px;
        left: 15px;
      }

      .title {
        max-width: 108px;
        right: 15px;
        color: $color-text-light-medium;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      figure {
        height: 60px;
        margin-bottom: 15px;
      }

      .graph {
        margin: 0;
      }

      .chart,
      > p {
        height: 60px;
        margin: 0;
      }
    }
  }

  &.arguments {
    .slide {
      width: 210px;
      font-size: calc-rem(14px);
      color: $color-text-dark;

      &:hover {
        text-decoration: none;
      }
    }

    .vote {
      position: absolute;
      right: 35px;
      top: 2px;
      font-size: calc-rem(12px);
      background-color: $color-text-light;
      outline: 3px solid $color-text-light;

      > a {
        color: $color-text-dark;
      }

      .hover-menu {
        display: none;
      }
    }
  }
}

// 2 sliders in a row
.row.sliders {
  .slider {
    margin-bottom: 0;
    @include media-breakpoint-up('md') {
      margin: 20px 0 0 0;
    }

    &.arguments {
      @include media-breakpoint-up('md') {
        padding-right: 10px;
      }
    }

    &.stocks {
      @include media-breakpoint-up('md') {
        margin-left: 10px;
        ul:not(:empty) {
          &::before {
            display: block;
            position: absolute;
            left: 0;
            top: 30px;
            width: 1px;
            height: 92px;
            background-color: $color-text-light;
            content: '';
          }
        }
      }
    }
  }
}

// color settings for different backgrounds
.bg-dark,
.bg-black,
.bg-blue,
.bg-gray {
  .slider {
    &.trends,
    &.arguments,
    &.stocks {
      .slide {
        color: $color-text-light;
        border: 1px solid $color-divider;

        &:hover {
          border-color: $color-text-light;
        }
      }

      .vote {
        > a {
          color: $color-text-light;
        }

        .count {
          color: $color-text-light-medium;
        }
      }
    }
  }

  .controls {
    button {
      color: $color-text-light;
    }
  }
}


.bg-dark {
  .slider {
    &.trends,
    &.arguments,
    &.stocks {
      .slide {
        .count,
        .perf,
        .title {
          background-color: $color-header-dark;
          outline-color: $color-header-dark;
        }

        .marker {
          outline-color: $color-header-dark;
        }
      }

      .vote {
        background-color: $color-header-dark;
        outline-color: $color-header-dark;
      }
    }
  }
}

.bg-black {
  .slider {
    &.trends,
    &.arguments,
    &.stocks {
      .slide {
        .count,
        .perf,
        .title {
          background-color: $color-header-black;
          outline-color: $color-header-black;
        }

        .marker {
          outline-color: $color-header-black;
        }
      }

      .vote {
        background-color: $color-header-black;
        outline-color: $color-header-black;
      }
    }
  }
}

.bg-blue {
  .slider {
    &.trends,
    &.arguments,
    &.stocks {
      .slide {
        .count,
        .perf,
        .title {
          background-color: $color-header-blue;
          outline-color: $color-header-blue;
        }

        .marker {
          outline-color: $color-header-blue;
        }
      }

      .vote {
        background-color: $color-header-blue;
        outline-color: $color-header-blue;
      }
    }
  }
}

.bg-gray {
  .slider {
    &.trends,
    &.arguments,
    &.stocks {
      .slide {
        .count,
        .perf,
        .title {
          background-color: $color-header-gray;
          outline-color: $color-header-gray;
        }

        .marker {
          outline-color: $color-header-gray;
        }
      }

      .vote {
        background-color: $color-header-gray;
        outline-color: $color-header-gray;
      }
    }
  }
}
