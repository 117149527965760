// -----------------------------------------------------------------------------
// Page: Startseite
// -----------------------------------------------------------------------------

.home-header {
  .intro-head::after {
    margin-bottom: 20px;
  }
 }

.analysis-header {
  border-top: 1px solid $color-divider;
  h2.label {
    margin: 0 0 40px 0;
  }
}

.page-home {
  margin-bottom: 60px;
  @include media-breakpoint-down('sm') {
    .listview-header {
      border-bottom: 1px solid $color-bg-dark-medium;
    }
  }

  .arguments-analysis-list {
    @include media-breakpoint-down('sm') {
      .listview-header {
        border: 0;
        margin-bottom: 30px;
      }
    }
    padding-bottom: 40px;
    ul {
      li {
        > * {
          @include media-breakpoint-only('md') {
            &:nth-child(1) {
              flex: 0 0 145px;
            }
            &:nth-child(4) {
              flex: 0 0 150px;
              padding-left: 20px;
              text-align: left;
            }
          }
          @include media-breakpoint-up('lg') {
            &:nth-child(4) {
              flex: 0 0 200px;
            }
          }
          @include media-breakpoint-up('xl') {
            &:nth-child(4) {
              flex: 0 0 250px;
            }
          }
        }
      }
    }
  }
}

