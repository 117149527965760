// -----------------------------------------------------------------------------
// Page: Aktienliste
// -----------------------------------------------------------------------------

.page-stock-list {

  padding-bottom: 20px;

  .tab-nav {
    margin: 10px 20px 0 20px;
    border-bottom: 1px solid $color-border-light;

    @include media-breakpoint-up('md') {
      margin: 20px 0 0 0;
    }
  }

  .sortable {
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
  }

  .sortables {
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 34px;

    @include media-breakpoint-up('md') {
      padding-left: 0;
    }

    @include media-breakpoint-up('lg') {
      padding-right: 54px;
    }

    svg {
      display: inline-block;
      margin-right: 3px;
      @include media-breakpoint-up('md') {
        font-size: 16px;
        vertical-align: sub;
      }
    }
  }

  .stock-list {
    &.no-mobile-border {
      @include media-breakpoint-down('sm') {
        > :first-child {
          border-top: 0;
        }
      }
    }

    .stock {
      > .collapse:not(.show) {
        display: block;
      }

      .expander {
        display: none;
      }

      @include media-breakpoint-down('sm') {

        .expander {
          display: flex;
          justify-content: space-between;
          padding: 15px 20px;
          a {
            color: $color-text-light-medium;
            text-decoration: none !important;
            .fa-angle-down {
              margin-left: 5px;
              vertical-align: middle;
              transform: rotate(180deg);
              transition: all .2s;
            }
          }

          .visible-collapsed {
            display: none;
          }

          .visible-expanded {
            display: inline-block;
          }
        }

        > .collapsing {
          min-height: 40px;
        }

        > .collapse:not(.show) {
          position: relative;
          display: block;
          height: 40px;
          overflow: hidden;

          .row > * {
            position: static;
            max-width: 100%;
            flex-basis: 100%;
          }

          .info {
            > :not(h3) {
              display: none;
            }

            .meta:first-child {
              display: inline-block;
            }
          }

          & + .expander {
            .visible-collapsed {
              display: inline-block;
            }

            .visible-expanded {
              display: none;
            }

            .fa-angle-down {
              transform: rotate(0);
            }

          }

          h3 {
            position: absolute;
            top: 18px;
            left: 40px;
            width: calc(100% - 120px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 23px;
            line-height: 23px;

            &::before {
              content: none;
            }
          }

          .chart {
            margin: -20px 0 0 0;

            .graph {
              border: 0;
              opacity: 0;
            }

            .chart-perf {
              right: 0;
            }
          }

        }
      }

    }
  }

}
