// -----------------------------------------------------------------------------
// Colors
// -----------------------------------------------------------------------------

// backgrounds
$color-bg-dark: #121519;
$color-bg-dark-medium: #222222;
$color-bg-dark-lighter: #323232;
$color-bg-lightest: #ffffff;
$color-bg-light: #f4f4f4;
$color-bg-editor: $color-bg-dark;
$color-bg-userimage: #606060;

// text
$color-text-light: #ffffff;
$color-text-dark: #121519;
$color-text-light-medium: #9c9d9e;
$color-text-medium: #777777;
$color-link: #3395ff;

// form elements
$color-placeholder: #aaaaaa;
$color-input-underline: #cccccc;
$color-input-underline-focus: $color-text-dark;

// borders and dividers
$color-divider: #505050;
$color-divider-dark: #2c2c2c;
$color-border-light: #dddddd;
$color-invalid-border: #cc0000;
$color-invalid-message-bg: #ff9999;

// markers and charts
$color-pos: #24e800;
$color-pos-inactive: #91c388;
$color-neg: #e82400;
$color-neg-inactive: #c39188;
$color-unchanged: #808080;
$color-marker-blue: #007de8;
$color-marker-compare: darken(#007de8, 22%);

// progress nav in analysis dialog
$color-bg-progress-nav: $color-bg-dark-lighter;
$color-bg-progress-nav-active: $color-bg-dark;
$color-text-progress-nav: #7b7b7b;
$color-text-progress-nav-active: $color-text-light;

// vote button
$color-vote-light: $color-text-light;
$color-vote-dark: $color-text-dark;

// page header and analysis blocks
$color-header-dark: #272727;
$color-header-black: #000000;
$color-header-gray: #393d41;
$color-header-blue: #003fab;


// page header and analysis blocks background classes
.bg-black {
  background-color: $color-header-black;
  color: $color-text-light;

  .use-bg,
  .dygraph-axis-label-y {
    background-color: $color-header-black;
  }
}

.bg-gray {
  background-color: $color-header-gray;
  color: $color-text-light;

  .use-bg,
  .dygraph-axis-label-y {
    background-color: $color-header-gray;
  }
}

.bg-blue {
  background-color: $color-header-blue;
  color: $color-text-light;

  .use-bg,
  .dygraph-axis-label-y {
    background-color: $color-header-blue;
  }
}

