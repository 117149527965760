// -----------------------------------------------------------------------------
// Page: Trendliste
// -----------------------------------------------------------------------------

.page-trend-list {

  padding-bottom: 40px;

  .list-controls {
    position: relative;
    padding-top: 10px;
    @include media-breakpoint-down('sm') {
      margin-bottom: 55px;
      border-bottom: 1px solid $color-border-light;
      > div {
        margin-bottom: -1px;
      }
    }
    @include media-breakpoint-up('md') {
      padding-top: 20px;
      margin-bottom: -1px;
    }

    .search-input {
      position: relative;
      @include media-breakpoint-down('sm') {
        margin-right: 20px;
      }

      .fa-search {
        position: absolute;
        right: 10px;
        bottom: 19px;
        pointer-events: none;
        color: $color-text-light-medium;
      }

      .form-control {
        width: 120px;
        padding-right: 20px;
        @include media-breakpoint-up('sm') {
          width: 200px;
        }
        height: auto;
        box-shadow: none;
        border-radius: 0;
        padding-bottom: 6px;
      }
    }
  }

  .tab-nav {
    margin: 0 0 0 20px;

    @include media-breakpoint-up('md') {
      margin: 0;
    }
  }

  .sortables {
    @include media-breakpoint-down('sm') {
      position: absolute;
      display: flex;
      justify-content: center;
      padding: 0 30px;
      top: 67px;
    }
    @include media-breakpoint-down('xs') {
      justify-content: space-between;
    }
    line-height: 20px;
    margin-top: 10px;
  }

  .sortable {
    display: inline-block;
    padding-right: 16px;
    font-size: 16px;
    font-family: $font-family-extrabold;
    text-transform: uppercase;

    &::after {
      top: 2px;
    }

    @include media-breakpoint-up('sm') {
      & + .sortable {
        margin-left: 40px;
      }
    }
  }

  .trend-list {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      column-rule: 1px solid $color-border-light;
      @include media-breakpoint-up('sm') {
        columns: 2;
        column-gap: 40px;
      }
      @include media-breakpoint-up('md') {
        columns: 3;
      }
      @include media-breakpoint-up('lg') {
        columns: 4;
      }

      li {
        position: relative;
        break-inside: avoid;
        padding: 4px 40px 4px 0;
        line-height: 1.2;
        word-break: break-word;
        hyphens: auto;
      }

      a {
        color: $color-text-dark;
      }

      span {
        display: none;
        position: absolute;
        top: 4px;
        right: 0;
        text-align: right;

        &::after {
          content: '%';
        }

      }

      &.show-tl1m .tl1m {
        display: inline;
      }

      &.show-tl1y .tl1y {
        display: inline;
      }

      &.show-tl5y .tl5y {
        display: inline;
      }
    }
  }
}
