// -----------------------------------------------------------------------------
// Pagination
// -----------------------------------------------------------------------------

.pagination {
  margin: 40px 0 20px 0;
  text-align: center;
  font-family: $font-family-bold;
  line-height: $pagination-button-small !important;
  @include media-breakpoint-up('sm') {
    line-height: $pagination-button !important;
  }

  .page-link {
    width: $pagination-button-small;
    height: $pagination-button-small;
    line-height: $pagination-button-small !important;
    padding: 0 !important;
    @include media-breakpoint-up('sm') {
      width: $pagination-button;
      height: $pagination-button;
      line-height: $pagination-button !important;
    }
    color: $color-text-dark;
    text-decoration: none;
    border: 0;

    &:hover {
      background-color: $color-text-light-medium;
    }
  }

  .page-item {
    width: $pagination-button-small;
    @include media-breakpoint-up('sm') {
      width: $pagination-button;
    }

    &.active .page-link {
      background-color: $color-bg-dark-medium;
      color: $color-text-light;
    }

    &.disabled {
      opacity: .4;
      pointer-events: none;
    }
  }
}
