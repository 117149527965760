.sortable {
  position: relative;
  cursor: pointer;
  color: $color-text-dark;
  &:hover {
    color: $color-text-dark;
    text-decoration: none;
  }

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    margin-left: 7px;
    top: 1px;
    width: 9px;
    height: 16px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAcCAMAAACnDzTfAAAAVFBMVEUAAAD///8AAAAAAAD///8AAAD///8AAAD///////8AAAD///////8AAAAAAAD///////////8AAAD///////8AAAD///8AAAD///8AAAD///8AAABViml2AAAAGnRSTlMACxwHZkBbOSvk5AVsbAofHVc2ENnZKCgVFSLFbuEAAAC2SURBVCjPvZDbEsIgDAUDtGqrQu/X//9PT0pMpqPPzdPOLjMQCBPugY7x9ZqBjUDZhZz20asR8CXOcXrvtVeTgRg5RY5qKAAIGD0SIc5q6OqJcnNQmOVR3nb4WcY2/fcJcB3MCfwoyX7WYP0mcjeXoakWMwJF63LahqRG4FHgHKfXViU1GZ6MnBxHNeQABOwTEiFOaujq6eVmpzDJo5LtoJBkmcY2NUDMCa6FOUEaJNnPGixH+gAwVAxlfltdxAAAAABJRU5ErkJggg==);
  }

  &.desc {
    &::after {
      background-position: 9px 0;
    }
  }

  &.asc {
    &::after {
      background-position: 18px 0;
    }
  }
}
