// -----------------------------------------------------------------------------
// Buttons
// -----------------------------------------------------------------------------

$pagination-button: 48px;
$pagination-button-small: 32px;

// common button styles
.btn {
  text-transform: uppercase;
  white-space: nowrap;

  font-family: $font-family-bold;

  &.disabled,
  &:disabled {
    opacity: .4;
    cursor: not-allowed;
  }

  > svg {
    margin-right: 5px;
  }

  &.icon-left,
  &.icon-right {
    position: relative;

    > svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.icon-left {
    > svg {
      left: 1.2rem;
    }
  }

  &.icon-right {
    > svg {
      right: 1.2rem;
      margin-left: 5px;
      margin-right: 0;
    }
  }

  &.collapse-control {
    display: block;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: left;
    font-family: $font-family-extrabold;

    @include media-breakpoint-down('sm') {
      white-space: normal;
    }

    svg {
      transition: all .2s;
      transform: translateY(-50%) rotate(180deg);
    }

    &.collapsed {
      svg {
        transform: translateY(-50%) rotate(0);
      }
    }
  }

  &.btn-dark {
    background-color: $color-bg-dark;
  }
}

// pos / neg radio buttons in analysis dialog
.radio-button {
  position: relative;

  label {
    margin: 0;
  }

  input {
    position: absolute;
    opacity: 0;

    & + label {
      color: #ffffff;

      &:hover {
        color: #ffffff;
      }

      &.btn-vote-pos {
        background-color: $color-pos-inactive;

        &:hover {
          background-color: $color-pos;
        }
      }

      &.btn-vote-neg {
        background-color: $color-neg-inactive;

        &:hover {
          background-color: $color-neg;
        }
      }
    }

    &:focus + label {
      &.btn-vote-pos {
        box-shadow: 0 0 0 0.2rem rgba(69, 235, 38, 0.5);
      }

      &.btn-vote-neg {
        box-shadow: 0 0 0 0.2rem rgba(255, 117, 117, 0.5);
      }
    }

    &:checked + label {
      padding-bottom: .3rem;

      &.btn-vote-pos {
        background-color: $color-pos;
        box-shadow: inset 0 5px 10px 0 rgba(0, 0, 0, 0.3);
      }

      &.btn-vote-neg {
        background-color: $color-neg;
        box-shadow: inset 0 5px 10px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }

  label + input + label {
    margin-left: 20px;
  }
}

// add new and load more buttons
.add-new,
.load-more {
  text-align: center;
  @extend %container-padding-40-40;
  border-bottom: 1px solid $color-border-light;
  @include media-breakpoint-down('sm') {
    border-top: 1px solid $color-border-light;
  }

  &:last-child {
    border-bottom: 0;
  }

  .btn {
    display: block;
    width: 100%;
    @include media-breakpoint-up('md') {
      display: inline-block;
      width: auto;
    }
  }
}

.add-new {
  .btn {
    padding-left: 40px;
  }
}
