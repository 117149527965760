// -----------------------------------------------------------------------------
// Page: Aktienfinder
// -----------------------------------------------------------------------------

.stock-finder-header {

  position: relative;

  .listview-header {
    margin: -20px 0 20px 0;
    border-color: $color-divider;

    @include media-breakpoint-up('md') {
      height: 80px;
      .tab-nav {
        margin: 18px auto 0 0;
        a {
          padding-bottom: 26px;
        }
      }
    }

    @include media-breakpoint-up('lg') {
      margin-top: -30px;
    }
  }

  .table-wrap-trends {
    height: 215px;

    tr {
      :nth-child(2) {
        text-align: center;

        a {
          display: inline-block;
          padding: 0 10px;

          &:hover {
            color: $color-link;
          }
        }
      }

      :nth-child(3) {
        text-align: right;
      }
    }
  }

  .trend-wrap {
    margin-top: 20px;
    @include media-breakpoint-up('md') {
      margin-top: 0;
    }

    legend.label {
      display: inline-block;
      width: auto;
      padding: 0 5px;
      margin-left: -5px;

      a {
        color: $color-text-light;
      }
    }
  }

  fieldset {
    width: 100%;
    margin-top: 60px;

    legend {
      display: none;
    }
  }


  .col-chart {
    @include media-breakpoint-down('md') {
      order: unset;
      margin-bottom: 40px;
    }

    @include media-breakpoint-up('lg') {
      padding-top: 13px;
      padding-right: 15px;
    }

    .chart-container {
      padding-top: 12px;
      height: 200px;
      @include media-breakpoint-up('lg') {
        height: calc(100% - 88px);
        min-height: 218px;
      }
    }
  }

  .col-table {
    padding-bottom: 30px;
    margin-bottom: 20px;

    @include media-breakpoint-down('md') {
      order: unset;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    @include media-breakpoint-up('lg') {
      min-height: 298px;
      .data-table {
        border-bottom: 1px solid $color-divider;
      }
    }
  }

  .col-image-wide {
    figure {
      margin: 0;
    }
  }

  .sortable {
    color: $color-text-light;
  }

  .col-chart-trend,
  .col-chart-stock {
    .chart-title {
      position: absolute;
      top: -35px;
    }

    .chart-container {
      height: 200px;
    }
  }

  @include media-breakpoint-up('sm') {
    fieldset {
      padding: 20px 30px 30px 30px;
      margin-top: 40px;
      border: 1px solid $color-divider;
    }

    .col-table {
      padding: 0 10px;
    }

    .col-chart-trend,
    .col-chart-stock {
      margin-top: 30px;
    }
  }

  @include media-breakpoint-up('md') {
    .table-wrap-trends {
      position: absolute;
      width: 100%;
      height: calc(100% - 40px);
    }

    //.switch-trend-context {
    //  position: absolute;
    //  top: 17px;
    //  left: 20%;
    //
    //  a {
    //    padding-bottom: 24px;
    //  }
    //}

    fieldset {
      margin: 0;

      legend {
        display: inline-block;
        width: auto;
        padding: 0 5px;
        margin-left: -5px;
      }
    }
  }

  @include media-breakpoint-up('lg') {
    //.switch-trend-context {
    //  top: 32px;
    //  left: 33.333333%;
    //  margin-left: 10px;
    //
    //  a {
    //    padding-bottom: 32px;
    //  }
    //}

    .col-table {
      padding: 0 10px 0 15px;
    }

    .col-chart-trend {
      padding-right: 15px;
    }

    .col-chart-stock {
      padding-left: 15px;
    }
  }

  @include media-breakpoint-down('md') {
    .col-image {
      display: none;
    }

    .col-table {
      order: 2;
    }

    .col-chart-trend {
      order: 1;
      margin-bottom: 40px;
    }

    .col-chart-stock {
      order: 3;
    }
  }
}

.page-stock-finder {
  .content-box {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 20px;
    @include media-breakpoint-up('lg') {
      margin-bottom: 40px;
      padding-bottom: 30px;
    }

    .fa-search {
      position: absolute;
      top: 25px;
      right: 20px;
      @include media-breakpoint-up('lg') {
        right: 40px;
      }
      pointer-events: none;
      color: $color-text-light-medium;
    }
  }

  .charts {
    .embed-responsive {
      margin-top: 20px;
      overflow: visible;
    }

    .chart-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 20px 0 0 0;

      .graph {
        border-color: $color-text-light-medium;
      }

      .use-bg,
      .dygraph-axis-label-y {
        background-color: #FFFFFF;
      }
    }
  }
}
