// -----------------------------------------------------------------------------
// Helpers
// -----------------------------------------------------------------------------

// example:
// https://github.com/HugoGiraudel/sass-boilerplate/blob/master/stylesheets/base/_helpers.scss

%divider-before {
  &::before {
    content: '';
    display: block;
    margin: 20px 0;
    height: 1px;
    background-color: $color-divider;
    @include media-breakpoint-up('md') {
      margin: 30px 0;
    }
  }

  @at-root .row::before {
    width: calc(100% - 20px);
    margin-left: 10px;
  }

}

%divider-after {
  &::after {
    content: '';
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 1px;
    background-color: $color-divider;
    @include media-breakpoint-up('lg') {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  @at-root .row::after {
    width: calc(100% - 20px);
    margin-left: 10px;
  }

}

%container-padding-40-40 {
  padding: 20px;
  @include media-breakpoint-up('lg') {
    padding: 40px;
  }
}

%container-padding-20-40 {
  padding: 20px;
  @include media-breakpoint-up('lg') {
    padding: 20px 40px;
  }
}

%container-padding-20-20 {
  padding: 20px;
}

.pos {
  color: $color-pos !important;
}

.neg {
  color: $color-neg !important;
}

a.pos,
a.neg {
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}

.marker-pos,
.marker-neg {
  position: relative;

  &::before {
    position: absolute;
    display: block;
    left: -20px;
    width: 10px;
    height: 10px;
    @include media-breakpoint-up('lg') {
      left: -40px;
    }
    background-color: $color-pos;
    content: '';
  }


}

.marker-neg {
  &::before {
    background-color: $color-neg;
  }
}

h1.marker-pos,
h1.marker-neg {
  &::before {
    top: calc(4px + 1.5vw);
    @include media-breakpoint-up('md') {
      top: calc(2px + 1.5vw);
    }
    @include media-breakpoint-up('lg') {
      top: 14px;
      width: 20px;
      height: 20px;
    }
    @include media-breakpoint-only('lg') {
      top: calc(-6px + 1.6vw);
    }
  }
}

h2.marker-pos,
h2.marker-neg {
  &::before {
    top: 10px;
    @include media-breakpoint-up('lg') {
      top: 9px;
      width: 18px;
      height: 18px;
    }
  }
}

h3.marker-pos,
h3.marker-neg {
  &::before {
    top: 9px;
    @include media-breakpoint-up('lg') {
      top: 7px;
      width: 16px;
      height: 16px;
    }
  }
}

h4.marker-pos,
h4.marker-neg {
  &::before {
    top: 8px;
    @include media-breakpoint-up('lg') {
      top: 6px;
      width: 14px;
      height: 14px;
    }
  }
}

// containers that have an absolute positioned control on top like tabbar or dropdown
.container-control {
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
}

.has-container-control {
  padding-top: 0 !important;
}

.legend {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;

  &.blue-square {
    background: $color-marker-blue;
  }

  &.green-square {
    background: $color-pos;
  }

  &.red-square {
    background: $color-neg;
  }

  &.blue-circle {
    background: $color-bg-dark-medium;
    border: 1px solid $color-marker-blue;
    border-radius: 50%;
  }

  &.green-dot {
    background: $color-pos;
    border-radius: 50%;
  }

  &.red-dot {
    background: $color-neg;
    border-radius: 50%;
  }
}
