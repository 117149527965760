// VARIABLES -----------------------------------------------------------------------------------------------------------

$typeahead-font-size: 1rem !default;
$typeahead-line-height: 1.25 !default;
$typeahead-primary-color: #66afe9 !default;
$typeahead-cancel-color: #d0021b !default;
$typeahead-radius: 2px !default;
$typeahead-shadow: false !default; // true / false
$typeahead-dropdown-spacing: 5px !default;
$typeahead-outline-color: $typeahead-primary-color !default;
$typeahead-loading-size: 1.35rem !default;

$typeahead-padding-y: 5px;
$typeahead-padding-x: 0;

$typeahead-color: $color-text-light-medium;
$typeahead-border-color: #ccc !default;

$typeahead-group-color: $color-text-light-medium;
$typeahead-group-background: transparent;
$typeahead-group-border-color: $color-text-light-medium;

$typeahead-item-color: $color-text-light-medium;
$typeahead-item-background: #fff !default;
$typeahead-item-hover-background: #f5f5f5 !default;
$typeahead-item-disabled-color: #bababa !default;
$typeahead-item-disabled-background: #fafafa !default;

// TYPEAHEAD -----------------------------------------------------------------------------------------------------------

.typeahead__ {
  &container * {
    box-sizing: border-box;
    outline: 0;
  }

  &result {
    position: absolute;
    top: 60px;
    width: calc(100vw - 20px);
    right: -20px;
    @include media-breakpoint-up('md') {
      right: 0;
      width: 750px;
      opacity: 0;
    }
  }

  &query {
    position: relative;
    z-index: 2;
    width: 100%;
  }

  &container {
    input.disabled,
    input[disabled],
    button.disabled,
    button[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.65;
      box-shadow: none;
      background-color: #fff;
      border-color: $typeahead-border-color;
    }
  }

  &list {
    position: absolute;
    left: 0;
    z-index: 1000;
    width: 100%;
    padding: 10px 20px 20px 20px;
    margin: 2px 0 0 0;
    list-style: none;
    text-align: left;
    background-color: $color-bg-dark-medium;
    border: 0;
    border-radius: $typeahead-radius;
    background-clip: padding-box;
    @include media-breakpoint-up('md') {
      box-shadow: 0 0.5rem 1rem rgba(#000000, .5);
    }
  }

  &result.detached .typeahead__list {
    position: relative;
    z-index: 1041;
    top: initial;
    left: initial;
  }

  &list > li {
    position: relative;
  }

  &list .typeahead__item {
    &[disabled] {
      > a {
        cursor: not-allowed;
        color: $typeahead-item-disabled-color;
        background-color: $typeahead-item-disabled-background;
      }
    }

    > a {
      display: block;
      padding: $typeahead-padding-y $typeahead-padding-x;
      padding-right: 15px;
      clear: both;
      color: $typeahead-item-color;
      text-decoration: none;
      font-size: calc-rem(15px);
      line-height: calc-rem(17px);

      strong {
        font-weight: normal;
        font-family: $font-family-sans-serif;
        background: $color-bg-dark-lighter;
        color: $color-text-light;
      }

      svg {
        position: absolute;
        top: 8px;
        right: 0;
        display: none;
      }

      .hit-title {
        font-family: $font-family-bold;
        color: lighten($typeahead-item-color, 20%);
      }
    }

    &:not([disabled]) {
      > a:hover,
      > a:focus,
      &.active > a {
        color: $color-text-light;

        strong {
          background-color: #000000;
          color: lighten($typeahead-item-color, 20%);
        }

        svg {
          display: block;
        }

        .hit-title {
          color: $color-text-light;
        }
      }
    }
  }

  &list.empty {
    > li {
      padding: $typeahead-padding-y $typeahead-padding-x;
      color: $typeahead-item-color;
    }
  }

  &list > .typeahead__group {
    border-bottom: 1px solid $typeahead-group-border-color;
    padding-top: 15px;
    margin-bottom: 3px;

    > a,
    > a:hover,
    > a:focus,
    &.active > a {
      //cursor: default;
      color: lighten($typeahead-group-color, 30%);
      background: $typeahead-group-background;
      display: block;
      padding: $typeahead-padding-y $typeahead-padding-x;
      clear: both;
      text-decoration: none;
    }

    .hit-group {
      display: block;
      position: relative;
      width: 100%;
    }

    .group-title {
      font-family: $font-family-extrabold;
      text-transform: uppercase;
    }

    .group-title-hover {
      font-size: calc-rem(15px);
      position: absolute;
      right: 0;
      display: none;
    }

    > a:hover {
      color: $color-text-light;
      .group-title-hover {
        display: inline;
      }
    }
  }

  &list > {
    li.typeahead__group + li.typeahead__item {
      border-color: $typeahead-group-border-color;
    }
  }

  &container {
    &.result .typeahead__list,
    &.filter .typeahead__dropdown,
    &.hint .typeahead__hint,
    &.backdrop + .typeahead__backdrop {
      display: block !important;
    }

    .typeahead__list,
    .typeahead__dropdown,
    .typeahead__hint,
    + .typeahead__backdrop {
      display: none !important;
    }
  }

  &container.loading .typeahead__query {
    &:before,
    &:after {
      transition: all 0s linear, opacity 0.2s ease;
      position: absolute;
      z-index: 3;
      content: "";
      top: 50%;
      right: 0.55em;
      margin-top: -($typeahead-loading-size / 2);
      width: $typeahead-loading-size;
      height: $typeahead-loading-size;
      box-sizing: border-box;
      border-radius: 500rem;
      border-style: solid;
      border-width: 0.1em;
    }

    &:before {
      border-color: rgba(0, 0, 0, 0.35);
    }

    &:after {
      animation: button-spin 0.6s linear;
      animation-iteration-count: infinite;
      border-color: #fff transparent transparent;
      box-shadow: 0 0 0 1px transparent;
    }

    @keyframes button-spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
