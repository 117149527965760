// -----------------------------------------------------------------------------
// Richtext container
// -----------------------------------------------------------------------------


.richtext {
  @extend %container-padding-40-40;

  ol {
    counter-reset: section;
    list-style-type: none;
    padding: 0;

    ol, ul {
      margin: 0 0 20px 0;
      padding-left: 20px;
    }
  }

  li {
    strong {
      display: inline-block;
      font-size: 20px;
      margin-bottom: 5px;
    }

    &::before {
      font-size: 20px;
      font-family: $font-family-bold;
    }

    li {
      strong {
        font-size: 16px;
      }

      &::before {
        font-size: 16px;
      }
    }
  }

  ol > li:before {
    counter-increment: section;
    content: counters(section, '.') ' ';
  }


  .row {
    margin-bottom: 20px;
    ul {
      margin: 0;
      padding: 0 0 0 20px;
    }
  }

  table {
    td {
      vertical-align: top;
    }
  }
}

