// -----------------------------------------------------------------------------
// Basic styles
// -----------------------------------------------------------------------------

// example:
// https://github.com/HugoGiraudel/sass-boilerplate/blob/master/stylesheets/base/_base.scss

html,
body {
  height: 100%;
  overflow-x: hidden;
}

main {
  position: relative;
}

.container-fluid {
  max-width: 1400px;
  @include media-breakpoint-down('sm') {
    padding: 0;
  }
}

// input placeholder
::-webkit-input-placeholder {
  color: $color-placeholder;
}

:-moz-placeholder {
  color: $color-placeholder;
}

::-moz-placeholder {
  color: $color-placeholder;
}

::placeholder {
  color: $color-placeholder;
}

:-ms-input-placeholder {
  color: $color-placeholder;
}
