// -----------------------------------------------------------------------------
// Page: Fondportrait
// -----------------------------------------------------------------------------

.page-fond {

  .info-text {
    @include media-breakpoint-up('lg') {
      @include scroll-bar;
      max-height: 666px;
      overflow: auto;
    }
  }

  .data-table {
    &.table-stamm {
      tr:first-child td {
        border-color: $color-divider;
      }
    }
    &.table-perf {
      td {
        white-space: nowrap;
      }

      th {
        font-size: 14px;
        text-align: right;
      }
    }
  }

  .extra-info {
    margin: 30px 0 0 0;
    border-top: 1px solid $color-divider;
    border-bottom: 1px solid $color-divider-dark;

    .col {
      padding: 8px 0;
    }
  }

  .stock-list {
    .arguments {
      display: none;
    }
  }

  .fondswitch {
    margin: -10px auto 10px auto;
    @include media-breakpoint-up('md') {
      margin: 0;
    }
  }

  .profit-chart-legend {
    position: absolute;
    width: 100%;
    bottom: 5px;
    @include media-breakpoint-up('md') {
      bottom: 10px;
    }
    font-family: $font-family-sans-serif;
  }

  .profit-chart {
    .chart {
      margin: 0 20px 20px 20px;
      @include media-breakpoint-up('md') {
        margin: 25px 20px 0 0;
      }
      @include media-breakpoint-up('lg') {
        margin: 25px 40px 0 0;
      }
    }
  }

  .listview-header .chartswitch {
    @include media-breakpoint-up('md') {
      margin-right: 40px;
    }
  }
}

