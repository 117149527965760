// -----------------------------------------------------------------------------
// Default theme
// -----------------------------------------------------------------------------

// Adjustments after move from Proxima-Nova to Figtree
h1, .h1 {
  font-size: 2.4rem;
}
h2, .h2 {
  font-size: 1.8rem;
}

// remove spinner arrows in number inputs
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mousemode {
  *:focus {
    box-shadow: none;
  }
}

::-ms-clear {
  display: none;
}

.responsive-image {
  picture {
    display: flex;
    height: 100%;
  }
  img {
    width: 100%;
    height: auto;
    //object-fit: cover;
  }
}

[data-toggle="popover"] {
  cursor: help !important; // We are using 'button' instead of 'a', hence the !important is important.
}

button[data-toggle="popover"] {
  background: inherit;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

b, strong {
  font-weight: normal;
  font-family: $font-family-bold;
}

.pageloading-mask {
  background-color: transparent;
}

// cookie-dialog
#dialog-cookie .modal-body p a {
  color: white;
  text-decoration: underline;
}

// break long words
.div-text-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 100%;
  hyphens: auto;
}

.popover .arrow {
  display: none;
}

// ----------------------------------------------------------------------------------------------------------------

article.stock {
  margin-bottom: 20px;
}

article.comment {
  margin-bottom: 30px;
}

.analysis-instrument {
  margin-top: 15px;
  padding-top: 15px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: lightgrey;
}

a.instruments, a.trends, a.users {
  font-size: smaller;
  color: #329632;
}

a:visited.instruments, a:visited.trends {
  font-size: smaller;
  color: black;
}


.page-header .perf-abs {
  font-size: 130%;
  font-weight: bold;
}

.page-header .perf-rel {
  font-size: 90%;
}

/* Fix for instrument portraits where the instrument is not related to any trends
   Controlled by rendering an empty block in this case. */
.stock-header .related-placeholder {
  height: 252px;
}


/* TMP no blue links in homepage header */
.page-home h1 a {
  color: inherit;
}

/* TMP no blue links in homepage header */
.block-analysis h3 a {
  color: inherit;
}


/* TMP fix performance chart label positioning */
.chart .chart-perf {
  right: 23px;
}

.page-trend-list h1 svg {
  margin-right: .5em;
  font-size: 50%;
}


.alert.alert-dark {
  background-color: #2A2A2A;
  color: lightgray;
  border: 1px black;
}
